import { Fragment} from 'react';
import {
    Box,
    Button,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { AmountFormat } from "@trovio-tech/trovio-core-api-jsx";
import { Column, ColumnType, DeltaLevel, DeltaLevelSummary, ExpandedRows, Row, RowType, TOTAL_COLUMN_LABEL } from './DeltaLadderTypes';
import { ProductState } from './DeltaLadderProductState';

// TODO: Remove references below
import { currentDateExportFormat } from '@commodity-desk/common';

/**
 * Used to render the body component of a single delta ladder table.
 * When this function is changed, you must also update the formatDeltaBodyForExport function to match (That function is used to render CSV data for export)
 * 
 * @param summary                        The DeltaLevelSummary that contains all data for the delta ladder table that we are rendering here.
 * @param handleAmountClick              The function to call when an amount cell is clicked in this table
 * @param handleRowClick                 The function to call when a row is clicked in this table
 * @param numberDecimalPointCharacter    The character to use as a decimal point when rendering numbers (locale specific)
 * @param numberGroupSeparatorCharacter  The character to use as a thousands separator when rendering numbers (locale specific)
 * @param navHoverBackgroundColor        The background colour to use when hovering over a table header nav component (theme specific)
 * @param nestedTableBackgroundColors    The background colours to use when rendering rows that were expanded after clicking (theme specific)
 * @param productState                   The current product state, used to determine rounding decimal places when rendering numbers.
 */
const DeltaLadderBody = ({
    summary,
    handleAmountClick,
    handleRowClick,
    numberDecimalPointCharacter,
    numberGroupSeparatorCharacter,
    navHoverBackgroundColor,
    nestedTableBackgroundColors,
    productState
} : {
    summary: DeltaLevelSummary;
    handleAmountClick: (event: any, summary: DeltaLevelSummary, column: Column, row: Row) => void,
    handleRowClick: (event: any, baseRowKey: Row, summary: DeltaLevelSummary, fromExpandedRows?: ExpandedRows) => void,
    numberDecimalPointCharacter: string,
    numberGroupSeparatorCharacter: string,
    navHoverBackgroundColor: string,
    nestedTableBackgroundColors: string[],
    productState?: ProductState
}) => {

    const formatDeltaAmount = (value: number) => {
        let minDecimalPos = 0;
        let maxDecimalPos = 0;
        if (productState !== undefined) {
            minDecimalPos = productState.product.minDecimalPlaces;
            maxDecimalPos = productState.product.maxDecimalPlaces;
        }
        if (isNaN(value)) {
            return <>-</>
        }
        return <AmountFormat amount={value} minDecimalPos={minDecimalPos} maxDecimalPos={maxDecimalPos} groupSep={numberGroupSeparatorCharacter} decimalPoint={numberDecimalPointCharacter} fixedWidth={false} />;
    }

    // All cells are clickable except for the 'Net Delta' row, those with no data (displaying 0),
    // and the L1 Totals row (as we cannot display multiple products in one view)
    const shouldRenderAsButton = (row: Row, column: Column): boolean => {
        return row.rowType !== RowType.NET_DELTA
        && column.get(row.index) !== 0
        && !(column.columnType === ColumnType.TOTAL && summary.level === DeltaLevel.L1);
    }

    const backgroundColour = (depth: number) => {
        return nestedTableBackgroundColors[depth-1] ?? '';
    }

    // Render a single amounts cell in the table
    const renderCell = (column: Column, row: Row) => {
        return <TableCell
            key={`${summary.level}-${column.key}`}
            align='right'
        >
            {shouldRenderAsButton(row, column)
                ? (
                    <Button
                        onClick={(event) => handleAmountClick(event, summary, column, row)}
                        color='inherit'
                        sx={{
                            cursor: 'pointer',
                            justifyContent: 'right',
                            '&:hover': {
                                backgroundColor: navHoverBackgroundColor,
                            }
                        }}
                    >{formatDeltaAmount(column.get(row.index))}
                    </Button>
                )
                : (<Box sx={{ paddingRight: '8px' }}>{formatDeltaAmount(column.get(row.index))}</Box>)}
        </TableCell>
    }

    // Render a single row in the table
    const renderRow = (row: Row, columns: Column[], totalColumn: Column, expandedRows?: ExpandedRows, expansionDepth?: number) => {
        return (
            <TableRow hover
                key={`${summary.level}-${row.index}`}
                onClick={(event) => handleRowClick(event, row, summary, expandedRows)}
                sx={{
                    ...expansionDepth !== undefined ? {backgroundColor: backgroundColour(expansionDepth)} : {},
                    ...row.canExpand() ? row.isExpanded ? {cursor: 'zoom-out'} : {cursor: 'zoom-in'} : {}
                }}
            >
                <TableCell variant='head' sx={{ fontWeight: 'bold' }}>
                    <Tooltip title={row.tooltip()} placement="bottom-start">
                        <Typography sx={{marginLeft: `${30 * (expansionDepth ?? 0)}px`}}>{row.label}</Typography>
                    </Tooltip>
                </TableCell>
                {columns.map(column => (
                    renderCell(column, row)
                ))}
                {renderCell(totalColumn, row)}
            </TableRow>
        )
    }

    // Render any expanded rows, if relevant
    const renderExpandedRows = (row: Row, expandedRows: ExpandedRows, expansionDepth: number) => {
        return expandedRows.expandedRows.map(sublevelExpandedRow => (
            <Fragment key={`${summary.level}-${sublevelExpandedRow.label}-${expansionDepth}`}>
                {renderRow(sublevelExpandedRow, expandedRows.columns, expandedRows.totalColumn, expandedRows, expansionDepth)}
                {
                    expandedRows.sublevelExpandedRows.get(sublevelExpandedRow.label) !== undefined &&
                    renderExpandedRows(row, expandedRows.sublevelExpandedRows.get(sublevelExpandedRow.label)!, expansionDepth + 1)
                }
            </Fragment>
        )) ?? []
    }

    return (
        <TableBody>
            {summary.rows.map(row => (
                <Fragment key={`${summary.level}-${row.index}-row`}>
                    {renderRow(row, summary.columns, summary.total)}
                    {summary.expandedRows.get(row.label) !== undefined && renderExpandedRows(row, summary.expandedRows.get(row.label)!, 1)}
                </Fragment>
            ))}
        </TableBody>
    );
}

/**
 * Download a CSV file
 * @param summary  The current summary data
 */
const downloadCsv = (summary: DeltaLevelSummary) => {

    /**
     * The display value for an attribute, used in the filename for CSV exports
     * @param attribute  The raw attribute value
     * @returns          An attribute value that is cleaned for display
     */
    const attributeDisplayValueForCsvExport = (attribute: string) => {
        return attribute.replace('mbl_', '');
    }

    let filename = 'delta-ladder'
    if (summary.is(DeltaLevel.L1)) {
        filename += '-l1';
    }
    if (summary.is(DeltaLevel.L2)) {
        filename += `-l2-${summary.label}-${attributeDisplayValueForCsvExport(summary.groupBy!)}`;
    }
    if (summary.is(DeltaLevel.L3)) {
        filename += `-l3-${summary.parent!.label}-${summary.label}`;
    }
    filename += `-${currentDateExportFormat()}`;
    var data = formatDeltaBodyForExport(summary);
    const options = {
        filename: filename,
        fieldSeparator: ',',
        decimalSeparator: '.',
        quoteCharacter: '"',
        showLabels: true,
        useKeysAsHeaders: true,
        useBom: true
    };
    const csvConfig = mkConfig(options);
    const csv = generateCsv(csvConfig)(data);
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    anchor.addEventListener('click',  ()=> {
        download(csvConfig)(csv);
    });
    anchor.click();
    document.body.removeChild(anchor);

}

// Used for CSV export. Must match structure of DeltaLadderBody function below
const formatDeltaBodyForExport = (summary: DeltaLevelSummary): any[] => {
    const formatRowForExport = (row: Row, columns: Column[], totalColumn: Column, expansionDepth?: number) => {
        return {
            ...{
                '': '    '.repeat(expansionDepth ?? 0) + row.label
            },
            ...columns.reduce(function(map: any, column) {
                map[`${wrapWithQuotesForExport(column.label)}`] = column.get(row.index);
                return map;
            }, {}),
            ...{
                [TOTAL_COLUMN_LABEL]: totalColumn.get(row.index)
            },
        }
    }

    const wrapWithQuotesForExport = (value: string): string => {
        return `"${value.replace(/"/g, match => `""`)}"`;
    }

    const formatExpandedRowsForExport = (row: Row, expandedRows: ExpandedRows, expansionDepth: number) => {
        let formattedRows: any[] = [];
        for (let sublevelExpandedRowKey of expandedRows.expandedRows) {
            formattedRows.push(formatRowForExport(sublevelExpandedRowKey, expandedRows.columns, expandedRows.totalColumn, expansionDepth));
            if (expandedRows.sublevelExpandedRows.get(sublevelExpandedRowKey.label) !== undefined) {
                formattedRows = formattedRows.concat(formatExpandedRowsForExport(row, expandedRows.sublevelExpandedRows.get(sublevelExpandedRowKey.label)!, expansionDepth + 1));
            }
        }
        return formattedRows;
    };

    let formattedRows = [];
    for (let row of summary.rows) {
        formattedRows.push(formatRowForExport(row, summary.columns, summary.total));
        if (summary.expandedRows.get(row.label) !== undefined) {
            formattedRows = formattedRows.concat(formatExpandedRowsForExport(row, summary.expandedRows.get(row.label)!, 1))
        }
    }
    return formattedRows;
}

export { DeltaLadderBody, downloadCsv };