var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { ThemeProvider } from '@emotion/react';
import { Tab, Tabs } from '@mui/material';
export var StyledTabs = function (_a) {
    var value = _a.value, onChange = _a.onChange, tabs = _a.tabs, customTheme = _a.customTheme, basePath = _a.basePath, linkComponent = _a.linkComponent;
    // tab item text highlighting
    var hoverTabTextColor = { color: customTheme.theme.palette.secondary.main };
    return (_jsx(_Fragment, { children: _jsx(ThemeProvider, __assign({ theme: customTheme.theme }, { children: _jsx(Tabs, __assign({ value: value, onChange: function (event, newValue) {
                    onChange(event, newValue);
                } }, { children: tabs.map(function (tab) {
                    var _a;
                    return (_jsx(Tab, __assign({ label: tab.label, value: tab.value }, tab.to && {
                        component: linkComponent,
                        to: tab.to
                    }, { sx: __assign(__assign({}, hoverTabTextColor), { transition: customTheme.customProps.navHoverTransition, '&:hover': {
                                backgroundColor: customTheme.customProps.navHoverBackgroundColor,
                                color: (_a = customTheme.customProps.navTextHover) !== null && _a !== void 0 ? _a : null
                            } }) }), tab.value));
                }) })) })) }));
};
