import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    LinearProgress,
    Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { submitUnsignedTransaction, useAuth, useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';
import dayjs from 'dayjs';
import { 
    AssignHoldingRequest, 
    AssignHoldingRequestDeflationModeEnum 
} from '@trovio-tech/trovio-core-api-js';
import { 
    AlertDialogContent, 
    AlertType,
    AmountFormatWrapper,
    ControlledTextField,
    DATE_DISPLAY_FORMAT,
    getFieldDefinitionMap,
    getFieldDefinitions,
    getOrderedProjectsAndAmounts,
    getProductAttributeUiElements,
    getTradeOptimiserOptions,
    inventoryAccount,
    ProductBase, 
    ProductType, 
    renderDialogField,
    renderEntry,
    renderOrderedProjectVintageAmounts,
    ResettableFormFields,
    TradeOptimiser,
    TradeOptimiserAmountType,
    transactionAttributes,
    TransactionOverview,
    TransactionType,
    useAppConfigState,
    useCommodityDeskApiContext,
    useProductDataState,
    useProductItemFilters,
    useSubmitTransaction,
} from '@commodity-desk/common';

interface ForwardDeliverForm {
    product: string,
    projectType: string,
    project: string,
    vintage: string,
    projectState: string,
    country: string,
    // LGC
    fuelSource: string,
    creationYear: string,
    generationYear: string,
    generationState: string,
    greenPowerAccredited: string,
    // MiQC
    segment: string,
    issueYear: string,
    miqGrade: string,
    // other
    quantity: number,
    tradeOptimiser: TradeOptimiser
}

const defaultValues: ForwardDeliverForm = {
    product: '',
    projectType: '',
    project: '',
    vintage: '',
    projectState: '',
    country: '',
    // LGC
    fuelSource: '',
    creationYear: '',
    generationYear: '',
    generationState: '',
    greenPowerAccredited: '',
    // MiQC
    segment: '',
    issueYear: '',
    miqGrade: '',
    // other
    quantity: 0,
    tradeOptimiser: TradeOptimiser.DEFAULT
};

const ForwardTradeDeliverDialog = ({
    dialogActive,
    forwardTradeRow,
    onActionTaken,
    onClose
}: {
    dialogActive: boolean;
    forwardTradeRow: any;
    onActionTaken: () => void;
    onClose: () => void;
}) => {

    const {
        control,
        getValues,
        resetField,
        reset,
        trigger,
        formState: { errors, isValid }
    } = useForm<ForwardDeliverForm>({ mode: 'onChange', defaultValues: defaultValues });

    const balanceErrorWatch = useWatch({
        name: ['product'],
        control
    });
    const { cortenAuthApi } = useCortenApiState();

    const {
        productOptions,
        projectTypeOptions,
        projectOptions,
        vintageOptions,
        projectStateOptions,
        countryOptions,
        fuelSourceOptions,
        creationYearOptions,
        generationYearOptions,
        generationStateOptions,
        greenPowerAccreditedOptions,
        segmentOptions,
        issueYearOptions,
        miqGradeOptions,
        availableBalance,
        onFilterChange,
        resetProductFilters
    } = useProductItemFilters({cortenAuthApi: cortenAuthApi});

    const { productsData } = useProductDataState();
    const appConfigState = useAppConfigState();
    const user = useAuth();
    const { commodityDeskApi } = useCommodityDeskApiContext();

    const [fixedFields, setFixedFields] = useState<string[]>([]);
    const [transactionErrorMessage, setTransactionErrorMessage] = useState<string>("");
    const [transactionErrorCode, setTransactionErrorCode] = useState<string>("");
    const [transactionWarnMessage, setTransactionWarnMessage] = useState<string>("");
    const [isTransactionInProgress, setIsTransactionInProgress] = useState<boolean>(false);
    const pendingTransaction = useRef<string | null>(null);
    const [transactionSuccess, setTransactionSuccess] = useState<boolean>(false);
    const [transactionInReview, setTransactionInReview] = useState<boolean>(false);
    const [balanceError, setBalanceError] = useState<string>();
    const [projectAmounts, setProjectAmounts] = useState<any[]>();
    const productItemIds = useRef<Promise<string[]>>();

    const fieldDefinitions = getFieldDefinitions({appConfigState: appConfigState, productsData: productsData});
    const fieldDefinitionMap = getFieldDefinitionMap({appConfigState: appConfigState, productsData: productsData});

    useEffect(() => {
        if (dialogActive) {
            let preSelectedData = getValues();

            preSelectedData.product = forwardTradeRow.productId;
            preSelectedData.projectType = forwardTradeRow.projectType ?? "";
            preSelectedData.project = forwardTradeRow.projectId ?? "";
            preSelectedData.vintage = forwardTradeRow.vintage?.toString() ?? "";
            preSelectedData.projectState = forwardTradeRow.projectState ?? "";
            preSelectedData.country = forwardTradeRow.country ?? "";
            preSelectedData.fuelSource = forwardTradeRow.fuelSource ?? "";
            preSelectedData.creationYear = forwardTradeRow.creationYear?.toString() ?? "";
            preSelectedData.generationYear = forwardTradeRow.generationYear?.toString() ?? "";
            preSelectedData.generationState = forwardTradeRow.generationState ?? "";
            preSelectedData.greenPowerAccredited = forwardTradeRow.greenPowerAccredited?.toString() ?? "";
            preSelectedData.segment = forwardTradeRow.segment?.toString() ?? "";
            preSelectedData.issueYear = forwardTradeRow.issueYear?.toString() ?? "";
            preSelectedData.miqGrade = forwardTradeRow.miqGrade?.toString() ?? "";

            preSelectedData.quantity = forwardTradeRow.quantity;

            // These are fields enforced by the original Forward Trade that cannot be modified when carrying out the deliver trade
            setFixedFields(Object.keys(preSelectedData).filter(k => preSelectedData[`${k}` as keyof ForwardDeliverForm] !== ''));

            preSelectedData.tradeOptimiser = TradeOptimiser.DEFAULT;

            reset(preSelectedData, { keepDefaultValues: true });
            resetProductFilters(resetField, {...preSelectedData, account: inventoryAccount.id});
            setIsTransactionInProgress(false);
            pendingTransaction.current = null;
            setTransactionSuccess(false);
        }
    }, [dialogActive]); // eslint-disable-line react-hooks/exhaustive-deps

    // re-validate quantity when available balance changes
    useEffect(() => {
        if (availableBalance) trigger('quantity').then();
    }, [availableBalance, trigger]);

    useEffect(() => {
        let form = getValues();
        let error = undefined;
        if (!form.product) {
            error = 'Please select a Product';
        }
        setBalanceError(error);
    }, [balanceErrorWatch]); // eslint-disable-line react-hooks/exhaustive-deps

    const { submitAsync, transactionState, resetTransactionState } = useSubmitTransaction();

    useEffect(() => {
        if (transactionState.status === "EXECUTED") {
            setIsTransactionInProgress(false);
            setTransactionSuccess(true);
            onActionTaken();
        } else if (transactionState.status === "FAILED") {
            setIsTransactionInProgress(false);
            setTransactionErrorMessage('An error occurred when delivering units to the forward trade.');
            setTransactionErrorCode(transactionState.transactionErrorCode);
        }
    }, [transactionState]);

    const handleDialogClose = () => {
        resetTransactionState();
        setIsTransactionInProgress(false);
        pendingTransaction.current = null;
        setTransactionSuccess(false);
        reset();
        resetProductFilters(resetField);
        if (transactionInReview) {
            closeReviewDialog();
        }
        // update state in the parent view
        onClose();
    }

    const performTransaction = async () => {
        setIsTransactionInProgress(true);
        setTransactionInReview(false);
        const assignHoldingRequest: AssignHoldingRequest = {
            type: "AssignHoldingRequest",
            issuerId: inventoryAccount.id,
            holdingIds: [forwardTradeRow.forwardId],
            productItems: (await productItemIds.current)!,
            deflationMode: AssignHoldingRequestDeflationModeEnum.DeflateAndDelete,
            nonce: new Date().getTime(),
            // Using the default inflationMode of 'DontInflate',
            attributes: {
                ...forwardTradeRow.tradeId && { [`${transactionAttributes.tradeId.key}`]: forwardTradeRow.tradeId },
                [`${transactionAttributes.tradeDate.key}`]: forwardTradeRow.transactionTimestamp,
                [`${transactionAttributes.trader.key}`]: forwardTradeRow.trader,
                [`${transactionAttributes.price.key}`]: forwardTradeRow.price,
                [`${transactionAttributes.currency.key}`]: forwardTradeRow.currency,
                [`${transactionAttributes.valueDate.key}`]: forwardTradeRow.valueDate,
                ...forwardTradeRow.salesPerson && { [`${transactionAttributes.salesPerson.key}`]: forwardTradeRow.salesPerson },
                ...(forwardTradeRow.salesCredits || forwardTradeRow.salesCredits === 0) && { [`${transactionAttributes.salesCredits.key}`]: forwardTradeRow.salesCredits },
                ...forwardTradeRow.brokerName && { [`${transactionAttributes.brokerName.key}`]: forwardTradeRow.brokerName },
                ...(forwardTradeRow.brokerage || forwardTradeRow.brokerage === 0) && { [`${transactionAttributes.brokerage.key}`]: forwardTradeRow.brokerage },
                [`${transactionAttributes.transactionTypeInventory.key}`]: TransactionType.ForwardSellDeliver,
                [`${transactionAttributes.transactionTypeClient.key}`]: TransactionType.ForwardBuyDeliver
            }
        };

        submitAsync(assignHoldingRequest);
    };

    const onOptionSelect = (field: keyof ResettableFormFields) => {
        onFilterChange(resetField, {...getValues(), account: inventoryAccount.id}, field);
    };

    const openReviewDialog = () => {
        setTransactionInReview(true);
        getOrderedProjectsAndAmounts(
            getValues(),
            inventoryAccount.id,
            setProjectAmounts,
            productItemIds,
            appConfigState,
            cortenAuthApi,
            commodityDeskApi,
            handleError
        );
    };

    const handleError = () => {
        setTransactionInReview(false);
        setTransactionErrorMessage("Could not connect to CortenX. Please re-try.");
    };


    const closeReviewDialog = () => {
        setTransactionInReview(false);
        productItemIds.current = undefined;
        setProjectAmounts(undefined);
    };

    const currentProduct = appConfigState.getProduct(forwardTradeRow.productId);

    if (transactionSuccess) {
        return (
            <TransactionOverview
                open={transactionSuccess}
                onClose={handleDialogClose}
                title="Transaction Submitted successfully"
                uiElements={{
                    transactionId: { value: pendingTransaction.current! },
                    productId: { value: getValues().product },
                    ...getProductAttributeUiElements({
                        data: {
                            ...getValues(),
                            projectName: projectOptions?.values.find(
                                (opt) => opt.id === getValues().project
                            )?.label
                        },
                        fieldDefinitions: fieldDefinitions,
                        productType: appConfigState.getProduct(getValues().product)
                            ?.displayCode as ProductType
                    }),
                    quantity: { value: getValues().quantity.toString() }
                }}
                fieldDefinitionMap={fieldDefinitionMap}
                productType={
                    appConfigState.getProduct(getValues().product)?.displayCode as ProductType
                }
            />
        );
    }
        
    return (
        <Dialog open={dialogActive} onClose={onClose} fullWidth maxWidth="xs">

            {!transactionInReview && !transactionSuccess && !isTransactionInProgress && !transactionErrorMessage && !transactionWarnMessage && (
                <>
                    <DialogContent>
                        <Typography variant='h2'>
                            Deliver Units
                        </Typography>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '1rem' }}>
                            <Typography>
                                Deliver units to Forward:
                            </Typography>
                            <Box sx={{  width: '20em', textAlign: 'right', flex: '1'}}>
                                <Typography>{forwardTradeRow.forwardId}</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '1rem' }}>
                            <Typography>
                                Counterparty:
                            </Typography>
                            <Box sx={{  width: '20em', textAlign: 'right', flex: '1'}}>
                                <Typography>{forwardTradeRow.counterParty}</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '2rem' }}>
                            <Typography>
                                Value Date:
                            </Typography>
                            <Box sx={{  width: '20em', textAlign: 'right', flex: '1'}}>
                                <Typography>{dayjs(forwardTradeRow.valueDate).format(DATE_DISPLAY_FORMAT)}</Typography>
                            </Box>
                        </Box>

                        {
                            renderEntry(
                                'Product:',
                                (
                                    <ControlledTextField name='product' label='Product'
                                        options={productOptions}
                                        customOnChange={() => onOptionSelect('product')}
                                        rules={{ required: 'Product is required' }}
                                        control={control} errors={errors} reset={resetField}
                                        disabled={fixedFields.includes('product')}
                                        />
                                ),
                                true, true
                            )
                        }

                        {currentProduct?.productBase === ProductBase.Project &&
                            renderEntry('Project Type:', (
                                <ControlledTextField name='projectType' label='Project Type'
                                    options={projectTypeOptions}
                                    customOnChange={() => onOptionSelect('projectType')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('projectType')}
                                    balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                                    emphasizeUsableFields={true}
                                    />
                            ), true, true)}

                        {currentProduct?.productBase === ProductBase.Project &&
                            renderEntry('Project:', (
                                <ControlledTextField name='project' label='Project'
                                    options={projectOptions}
                                    customOnChange={() => onOptionSelect('project')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('project')}
                                    balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                                    emphasizeUsableFields={true}
                                    />
                            ), true, true)}

                        {currentProduct?.productBase === ProductBase.Project &&
                            renderEntry('Vintage:', (
                                <ControlledTextField name='vintage' label='Vintage'
                                    options={vintageOptions}
                                    customOnChange={() => onOptionSelect('vintage')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('vintage')}
                                    balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                                    emphasizeUsableFields={true}
                                    />
                            ), true, true)}

                        {currentProduct?.displayCode === ProductType.ACCU &&
                            renderEntry('State:', (
                                <ControlledTextField name='projectState' label='State'
                                    options={projectStateOptions}
                                    customOnChange={() => onOptionSelect('projectState')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('projectState')}
                                    balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                                    emphasizeUsableFields={true}
                                />
                            ), true, true)}

                        {currentProduct?.displayCode === ProductType.VCU &&
                            renderEntry('Country:', (
                                <ControlledTextField name='country' label='Country'
                                    options={countryOptions}
                                    customOnChange={() => onOptionSelect('country')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('country')}
                                    balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                                    emphasizeUsableFields={true}
                                />
                            ), true, true)}

                        {currentProduct?.displayCode === ProductType.LGC &&
                            renderEntry('Fuel Source:', (
                                <ControlledTextField name='fuelSource' label='Fuel Source'
                                    options={fuelSourceOptions}
                                    customOnChange={() => onOptionSelect('fuelSource')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('fuelSource')}
                                    balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                                    emphasizeUsableFields={true}
                                />
                            ), true, true)}

                        {currentProduct?.displayCode === ProductType.LGC &&
                            renderEntry('Creation Year:', (
                                <ControlledTextField name='creationYear' label='Creation Year'
                                    options={creationYearOptions}
                                    customOnChange={() => onOptionSelect('creationYear')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('creationYear')}
                                    balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                                    emphasizeUsableFields={true}
                                />
                            ), true, true)}

                        {currentProduct?.displayCode === ProductType.LGC &&
                            renderEntry('Generation Year:', (
                                <ControlledTextField name='generationYear' label='Generation Year'
                                    options={generationYearOptions}
                                    customOnChange={() => onOptionSelect('generationYear')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('generationYear')}
                                    balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                                    emphasizeUsableFields={true}
                                />
                            ), true, true)}

                        {currentProduct?.displayCode === ProductType.LGC &&
                            renderEntry('Generation State:', (
                                <ControlledTextField name='generationState' label='Generation State'
                                    options={generationStateOptions}
                                    customOnChange={() => onOptionSelect('generationState')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('generationState')}
                                    balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                                    emphasizeUsableFields={true}
                                />
                            ), true, true)}

                        {currentProduct?.displayCode === ProductType.LGC &&
                            renderEntry('GreenPower Accredited:', (
                                <ControlledTextField name='greenPowerAccredited' label='GreenPower Accredited'
                                    options={greenPowerAccreditedOptions}
                                    customOnChange={() => onOptionSelect('greenPowerAccredited')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('greenPowerAccredited')}
                                    balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                                    emphasizeUsableFields={true}
                                />
                            ), true, true)}

                        {currentProduct?.displayCode === ProductType.MiQC &&
                            renderEntry('Segment:', (
                                <ControlledTextField name='segment' label='Segment'
                                    options={segmentOptions}
                                    customOnChange={() => onOptionSelect('segment')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('segment')}
                                    balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                                    emphasizeUsableFields={true}
                                />
                            ), true, true)}

                        {currentProduct?.displayCode === ProductType.MiQC &&
                            renderEntry('Issue Year:', (
                                <ControlledTextField name='issueYear' label='Issue Year'
                                    options={issueYearOptions}
                                    customOnChange={() => onOptionSelect('issueYear')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('issueYear')}
                                    balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                                    emphasizeUsableFields={true}
                                />
                            ), true, true)}

                        {currentProduct?.displayCode === ProductType.MiQC &&
                            renderEntry('MiQ Grade:', (
                                <ControlledTextField name='miqGrade' label='MiQ Grade'
                                    options={miqGradeOptions}
                                    customOnChange={() => onOptionSelect('miqGrade')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('miqGrade')}
                                    balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                                    emphasizeUsableFields={true}
                                />
                            ), true, true)}

                        {renderEntry('Quantity:', (
                            <>
                                <ControlledTextField name='quantity' label='Quantity' integer
                                    control={control} errors={errors}
                                    disabled={true} />
                                {!balanceError && availableBalance == null
                                    ? (<LinearProgress sx={{ mt: 1.75, mb: 1.75, height: 8 }} />)
                                    : (<Typography variant='caption'
                                        color={balanceError ? 'error' : 'textSecondary'}
                                        mt={1} mb={1}
                                        sx={{ float: 'right' }}>
                                        {balanceError
                                            ? <div>{balanceError}</div>
                                            : <div>Available Balance: <AmountFormatWrapper
                                                amount={availableBalance}
                                                minDecimalPos={productsData.get(getValues().product)?.minDecimalPos!}
                                                maxDecimalPos={productsData.get(getValues().product)?.maxDecimalPos!}
                                            /></div>}
                                    </Typography>)}
                            </>
                        ), true, true)}
                        {(renderEntry('Trade Optimiser:', (
                            <ControlledTextField name='tradeOptimiser' label='Trade Optimiser'
                                options={getTradeOptimiserOptions(appConfigState.getProduct(forwardTradeRow.productId)?.displayCode as ProductType)}
                                rules={{ required: 'Trade optimiser is required' }}
                                control={control} errors={errors} reset={resetField}
                                emphasizeUsableFields={true}
                            />
                        ), true, true))}
                    </DialogContent>

                    <DialogActions>
                        {
                            availableBalance !== null &&
                            availableBalance < forwardTradeRow.quantity && (
                                <Typography sx={{ color: 'red', marginRight: 2 }}>
                                    Insufficient Balance
                                </Typography>
                            )
                        }

                        <Button onClick={handleDialogClose} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => openReviewDialog()}
                            color="primary"
                            variant="outlined"
                            disabled={
                                !isValid ||
                                !availableBalance ||
                                availableBalance < forwardTradeRow.quantity
                            }
                        >
                            {isTransactionInProgress ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '2px'
                                    }}
                                >
                                    <CircularProgress size={20} />
                                </Box>
                            ) : (
                                'Submit'
                            )}
                        </Button>
                    </DialogActions>
                </>
            )}
            {(transactionInReview || isTransactionInProgress) && (
                <>
                    <DialogContent>
                        <Typography variant='h2'>Review Details Below</Typography>
                        {getValues().product && renderDialogField('Product', currentProduct?.displayCode)}
                        {getValues().projectType && renderDialogField('Project Type', getValues().projectType)}
                        {getValues().project && renderDialogField(
                            'Project',
                            projectOptions?.values.find(opt => opt.id === getValues().project)?.label)
                        }
                        {getValues().vintage && renderDialogField('Vintage', getValues().vintage)}
                        {getValues().projectState && renderDialogField('State', getValues().projectState)}
                        {getValues().country && renderDialogField('Country', getValues().country)}
                        {getValues().fuelSource && renderDialogField('Fuel Source', getValues().fuelSource)}
                        {getValues().generationYear && renderDialogField('Generation Year', getValues().generationYear)}
                        {getValues().creationYear && renderDialogField('Creation Year', getValues().creationYear)}
                        {getValues().generationState && renderDialogField('Generation State', getValues().generationState)}
                        {getValues().greenPowerAccredited && renderDialogField('Greenpower Accredited', getValues().greenPowerAccredited)}
                        {getValues().segment && renderDialogField('Segment', getValues().segment)}
                        {getValues().issueYear && renderDialogField('Issue Year', getValues().issueYear)}
                        {getValues().miqGrade && renderDialogField('MiQ Grade', getValues().miqGrade)}
                        {renderDialogField('Quantity', getValues().quantity, {
                            minDecimalPos: productsData.get(getValues().product)?.minDecimalPos!,
                            maxDecimalPos: productsData.get(getValues().product)?.maxDecimalPos!,
                        })}
                        {getValues().tradeOptimiser === TradeOptimiser.CHEAPEST_FIRST && renderOrderedProjectVintageAmounts(projectAmounts, productsData.get(getValues().product)!, TradeOptimiserAmountType.Price)}
                        {getValues().tradeOptimiser === TradeOptimiser.INVENTORY_SCORE_BASED && renderOrderedProjectVintageAmounts(projectAmounts, productsData.get(getValues().product)!, TradeOptimiserAmountType.Score)}
                        {[TradeOptimiser.CHEAPEST_FIRST, TradeOptimiser.INVENTORY_SCORE_BASED].includes(getValues().tradeOptimiser) && projectAmounts?.map(p => p.balance).reduce((a,b) => a + b) < getValues().quantity && (<Typography variant='h3' sx={{marginTop: '10px'}}>Insufficient balance to complete transaction</Typography>)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => closeReviewDialog()} color='primary' variant='outlined'>Back</Button>
                        <Button
                            onClick={performTransaction}
                            color='primary'
                            variant='outlined'
                            disabled={
                                // eslint-disable-next-line no-mixed-operators
                                ([TradeOptimiser.CHEAPEST_FIRST, TradeOptimiser.INVENTORY_SCORE_BASED].includes(getValues().tradeOptimiser) &&
                                // eslint-disable-next-line no-mixed-operators
                                (!projectAmounts?.length) || projectAmounts?.map(p => p.balance).reduce((a,b) => a + b) < getValues().quantity)
                            }
                        >
                            {isTransactionInProgress ? (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '2px',
                                }}
                                >
                                    <CircularProgress size={20} />
                                </div>
                            ) : ('Confirm')}
                        </Button>
                    </DialogActions>
                </>
            )}
            {transactionErrorMessage && (
                <AlertDialogContent
                    alertType={AlertType.Error}
                    alertMessage={transactionErrorMessage}
                    errorCode={transactionErrorCode}
                    handleDialogClose={onClose}
                />
            )}
            {transactionWarnMessage && !transactionErrorMessage && (
                <AlertDialogContent
                    alertType={AlertType.Warning}
                    alertMessage={transactionWarnMessage}
                    errorCode={null}
                    handleDialogClose={onClose}
                />
            )}
        </Dialog>
    );
};

export { ForwardTradeDeliverDialog };
