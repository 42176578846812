import { Alert, Box, Dialog, LinearProgress, MenuItem, TextField, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import {
    fetchByCriteria,
    fetchCurveEndOfDayDates,
    fetchCurveMarking,
    fetchCurvePrices,
    carbonProjectAttributes,
    saveCurveMarking,
    useAppConfigState,
    useCommodityDeskApiContext
} from '@commodity-desk/common';
import { CurveMarkingTable } from "./CurveMarkingTable";
import CurvePrice from "./CurvePrice";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MapUtils from "./MapUtils";
import EditIcon from "@mui/icons-material/Edit";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { CurveButton } from "./CurveComponents";
import { CurvePricesTable } from "./CurvePricesTable";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { CurveMarking, ResponseError } from '@commodity-desk/commodity-desk-api-js';
import { useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';

/**
 * Top-level application component, allowing the user to modify the marked price
 * by altering the product price, and by setting "basis" values which apply based
 * on project attributes.
 */
const Curve = () => {
    const config = useAppConfigState();
    const { cortenAuthApi } = useCortenApiState();
    const { commodityDeskApi } = useCommodityDeskApiContext();

    const [productId, setProductId] = useState(config.getProducts(true)[0].id);
    const [curve, setCurve] = useState<CurveMarkingInternal | undefined>(undefined);
    const [projectNames, setProjectNames] = useState<Map<string, string>>(new Map());
    const [isEditing, setEditing] = useState(false);

    const [prices, setPrices] = useState<PricesResponse | undefined>(undefined);
    const [endOfDay, setEndOfDay] = useState<string | undefined>(undefined);
    const [endOfDayOptions, setEndOfDayOptions] = useState<string[]>([]);
    const [isPricesView, setPricesView] = useState(false);

    const [projectId, setProjectId] = useState("");
    const [vintage, setVintage] = useState("");
    const [isProjectView, setProjectView] = useState(false);
    const [negativePriceCount, setNegativePriceCount] = useState(0);


    useEffect(() => {
        setCurve(undefined);
        setPrices(undefined);
        setProjectId("");
        setVintage("");

        loadCurve(productId);
        loadPrices(productId);
        loadProjectNames(productId);
        loadEndOfDayDates(productId);
    }, [productId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setPrices(undefined);
        loadPrices(productId, endOfDay ? new Date(endOfDay) : undefined);
    }, [endOfDay]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (prices) {
            let count = 0;
            // eslint-disable-next-line array-callback-return
            MapUtils.entries(prices.prices).map(([rowKey, rowValue]) => {
                // eslint-disable-next-line array-callback-return
                MapUtils.entries(rowValue).map(([columnKey, columnValue]) => {
                    if (columnValue <= 0) {
                        count++;
                    }
                })
            });
            setNegativePriceCount(count)
        } else {
            setNegativePriceCount(0);
        }
    }, [prices]);

    const loadCurve = (productId: string): void => {
        setEditing(false);
        fetchCurveMarking({
            productId: productId,
            api: commodityDeskApi
        }).then(response => {
            setCurve(
                response ? {
                    ...response,
                    methodologyBases: toMap(response.methodologyBases),
                    vintageMethodologyBases: toSortedNestedMap(response.vintageMethodologyBases),
                    projectVintageBases: toNestedMap(response.projectVintageBases),
                } : undefined
            );
        });
    };

    const loadPrices = (productId: string, endOfDay?: Date): void => {
        fetchCurvePrices({
            productId: productId,
            endOfDay: endOfDay,
            api: commodityDeskApi
        }).then(response => {
            setPrices(response ? { ...response, prices: toSortedNestedMap(response.prices) } : undefined);
        });
    };

    const loadEndOfDayDates = (productId: string): void => {
        fetchCurveEndOfDayDates({
            productId: productId,
            api: commodityDeskApi
        })
            .then(dates => {
                setEndOfDayOptions(dates ? dates : []);
            });
    };

    const saveCurve = async (curveMarking: any, endOfDay: boolean = false): Promise<boolean> => {
        const curveType = endOfDay ? 'EOD' : 'CURRENT';
        const body: CurveMarking = {
            ...curveMarking,
            methodologyBases: toObject(curveMarking.methodologyBases),
            vintageMethodologyBases: toNestedObject(curveMarking.vintageMethodologyBases),
            projectVintageBases: toNestedObject(curveMarking.projectVintageBases),
        }
        try {
            await saveCurveMarking({
                curveMarking: body,
                endOfDay: endOfDay,
                api: commodityDeskApi
            });

            toast(<Alert severity='success'>{curveType} marking saved successfully</Alert>);
            // reset state to force a render, updating "initial" values for all subcomponents
            setCurve({ ...curveMarking });
            // reload prices and EOD dates as they (may) have been updated
            loadPrices(curveMarking.productId);
            loadEndOfDayDates(productId);
            return true;
        } catch (error) {
            if (error instanceof ResponseError) {
                toast(<Alert severity='error'>{`Failed to save ${curveType} marking - ${await error.response.text()}`}</Alert>);
            } else {
                toast(<Alert severity='error'>{`Failed to save ${curveType} marking`}</Alert>);
                console.error(`Failed to save ${curveType} marking - ${error}`)
            }
            return false;
        }
    };

    const loadProjectNames = (productId: string) => {
        const id = carbonProjectAttributes.projectId.key;
        const name = carbonProjectAttributes.projectName.key;
        fetchByCriteria({ productIds: [productId], axes: [id, name] }, cortenAuthApi).then(data => {
            const result = new Map<string, string>();
            data.list.forEach(item => result.set(item.attributes[id], item.attributes[name]));
            setProjectNames(result);
        });
    };

    const toMap = (obj: any): Map<string, number> => new Map(Object.entries(obj));

    const toObject = (map: Map<string, number>): Object => Object.fromEntries(map);

    /**
     * Sort by descending order of the map key. Use to sort by latest vintage first criteria.
     * @param table
     * @returns
     */
    const toSortedNestedMap = (table: any) => {
        const nestedMap = toNestedMap(table);
        const sortedEntries: [string, Map<string, number>][] = [];
        nestedMap.forEach((value, key) => sortedEntries.push([key, value]));
        sortedEntries.sort(([keyA], [keyB]) => keyB.localeCompare(keyA));
        const sortedMap = new Map(sortedEntries);

        return sortedMap;
    };

    const toNestedMap = (table: any) => {
        const result = new Map<string, Map<string, number>>();
        Object.entries(table).forEach(([key, value]: [string, any]) => result.set(key, toMap(value)));
        return result;
    };

    const toNestedObject = (map: Map<string, Map<string, number>>): Object => {
        const result = {} as any;
        MapUtils.entries(map).forEach(([key, value]) => result[key] = toObject(value));
        return result;
    };

    const buildTableFromSingleRow = (
        rowName: string,
        rowValues: Map<string, number>
    ): Map<string, Map<string, number>> => {
        let table = new Map<string, Map<string, number>>();
        table.set(rowName, rowValues);
        return table;
    };

    return (
        <>
            <Box sx={{ maxWidth: 'lg', margin: '0 0 auto' }}>
                <Typography variant='h2'>Curve Marker</Typography>
                {negativePriceCount > 0 && <Alert severity="warning">{`${negativePriceCount} Project-Vintage pairs have a computed price <= 0`}</Alert>}
                <Typography variant='h3' mt={2} mb={4}>Configure Curve Grid</Typography>
                <TextField
                    label='Product'
                    value={productId}
                    onChange={event => setProductId(event.target.value)}
                    select size='small' sx={{ marginRight: '1rem', width: 200 }}
                >
                    {config.getProducts(true).map((product) =>
                        <MenuItem key={product.id} value={product.id}>{product.displayCode}</MenuItem>
                    )}
                </TextField>
                {curve
                    ? <Fragment>
                        <CurvePrice
                            label='Base Price'
                            currency={curve.currency}
                            defaultValue={curve.productPrice}
                            handleEditing={setEditing}
                            disableEditing={isEditing}
                            handleSave={price => saveCurve({ ...curve, productPrice: price })}
                        />
                        <Box style={{ display: 'inline-flex', float: 'right' }}>
                            <CurveButton
                                disabled={isEditing}
                                onClick={() => {
                                    setEditing(true);
                                    saveCurve(curve, true).then(() => setEditing(false));
                                }}
                            >
                                <SaveAltIcon sx={{ mr: '5px', ml: '-5px' }} />SNAP EOD
                            </CurveButton>
                            <CurveButton
                                disabled={isEditing}
                                onClick={() => setPricesView(true)}
                                style={{ marginLeft: '5px' }}
                            >
                                <FormatListBulletedIcon sx={{ mr: '5px', ml: '-5px' }} />VIEW PRICES
                            </CurveButton>
                        </Box>

                        <Typography variant='h3' mt={5}>Methodology Marking</Typography>
                        <CurveMarkingTable
                            initialRows={buildTableFromSingleRow("Basis", curve.methodologyBases)}
                            handleSave={rows => saveCurve({ ...curve, methodologyBases: rows.get("Basis") })}
                            handleEditing={setEditing}
                            disableEditing={isEditing}
                            productPrice={curve.productPrice}
                            pricesRowNameResolver={() => `${config.getProduct(productId)?.displayCode} Methodology Price`}
                        />

                        <Typography variant='h3' mt={5}>Vintage Marking</Typography>
                        <CurveMarkingTable
                            initialRows={curve.vintageMethodologyBases}
                            handleSave={rows => saveCurve({ ...curve, vintageMethodologyBases: rows })}
                            handleEditing={setEditing}
                            disableEditing={isEditing}
                        />

                        <Typography variant='h3' mt={5} mb={4}>Project Marking</Typography>
                        <TextField
                            label='Project'
                            value={projectId}
                            onChange={event => setProjectId(event.target.value)}
                            select size='small' sx={{ marginRight: '1rem', width: 200 }}
                        >
                            {MapUtils.keys(curve.projectVintageBases).map((projectId) =>
                                <MenuItem key={projectId} value={projectId}>{projectNames.get(projectId)}</MenuItem>
                            )}
                        </TextField>
                        <TextField
                            label='Vintage'
                            value={vintage}
                            onChange={event => setVintage(event.target.value)}
                            disabled={!projectId}
                            select size='small' sx={{ marginRight: '1rem', width: 200 }}
                        >
                            {MapUtils.keys(curve.projectVintageBases.get(projectId)).map((vintage) =>
                                <MenuItem key={vintage} value={vintage}>{vintage}</MenuItem>
                            )}
                        </TextField>
                        {projectId && vintage &&
                            <CurvePrice
                                label='Basis'
                                currency={curve.currency}
                                defaultValue={curve.projectVintageBases.get(projectId)!.get(vintage)!}
                                handleEditing={setEditing}
                                disableEditing={isEditing}
                                handleSave={price => {
                                    const bases = MapUtils.deepCopy(curve!.projectVintageBases);
                                    bases.get(projectId)!.set(vintage, price);
                                    return saveCurve({ ...curve, projectVintageBases: bases });
                                }}
                            />
                        }
                        <CurveButton
                            disabled={isEditing}
                            onClick={() => setProjectView(true)}
                            style={{ float: 'right' }}
                        >
                            <EditIcon sx={{ mr: '5px', ml: '-5px' }} />EDIT ALL
                        </CurveButton>

                        <Dialog
                            open={isProjectView}
                            fullWidth
                            maxWidth='xl'
                            onClose={() => {
                                setProjectView(false);
                                setEditing(false);
                            }}
                        >
                            <Box m={4}>
                                <Typography variant='h3'>Project Marking</Typography>
                                <CurveMarkingTable
                                    dialogMode
                                    initialRows={curve.projectVintageBases}
                                    rowNameResolver={key => projectNames.get(key) ?? key}
                                    handleSave={rows => saveCurve({ ...curve, projectVintageBases: rows })}
                                    handleEditing={isEditing => {
                                        setEditing(isEditing);
                                        setProjectView(isEditing);
                                    }}
                                />
                            </Box>
                        </Dialog>

                        <Dialog open={isPricesView}
                            fullWidth
                            maxWidth='xl'
                            onClose={() => {
                                setPricesView(false);
                                // wait for the closing animation to finish before resetting end-of-day date
                                // and potentially triggering prices reload
                                setTimeout(() => setEndOfDay(undefined), 200);
                            }}
                        >
                            <Box m={4}>
                                <Typography variant='h3'>Project Prices</Typography>
                                {prices
                                    ? <CurvePricesTable
                                        prices={prices.prices}
                                        endOfDay={endOfDay}
                                        endOfDayOptions={endOfDayOptions}
                                        projectNames={projectNames}
                                        onEndOfDayChange={date => setEndOfDay(date)}
                                    />
                                    : <LinearProgress sx={{ mt: 4 }} />
                                }
                            </Box>
                        </Dialog>
                    </Fragment>
                    : <LinearProgress sx={{ mt: 4 }} />
                }
            </Box>
        </>
    )
};

/**
 * The complete curve marking, mapped from curve API response.
 * See CurveMarking.kt for details.
 */
interface CurveMarkingInternal {
    productId: string,
    productPrice: number,
    currency?: string,
    methodologyBases: Map<string, number>,
    vintageMethodologyBases: Map<string, Map<string, number>>,
    projectVintageBases: Map<string, Map<string, number>>,
}

/**
 * List of project prices, mapped from curve API response.
 * See VintageProjectPricesResponse.kt for details.
 */
interface PricesResponse {
    productPrice: number,
    prices: Map<string, Map<string, number>>,
}

export default Curve
