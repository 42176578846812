var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from "react";
import { inventoryAccount } from "./AppConfig";
import { fetchProducts } from "../utility/Fetch";
var ProductDataContext = createContext(null);
var ProductDataProvider = function (_a) {
    var appConfigState = _a.appConfigState, cortenAuthApi = _a.cortenAuthApi, children = _a.children;
    var _b = useState(new Map()), productsData = _b[0], setProductsData = _b[1];
    useEffect(function () {
        var newProductData = new Map();
        // Get the Regular Products
        var productIds = appConfigState.getProducts().map(function (p) { return { id: p.id }; });
        // Get the Emission and Decarbonised asset products
        Object.values(appConfigState.getCommoditiesConfig()['COMMODITY_PRODUCTS']).forEach(function (v) {
            productIds.push({ id: v['COMMODITY_PRODUCT']['ID'] });
            productIds.push({ id: v['LOW_CARBON_PRODUCT']['ID'] });
        });
        fetchProducts(cortenAuthApi, inventoryAccount.id, productIds).then(function (foundProducts) {
            if (foundProducts) {
                foundProducts.forEach(function (i) { return newProductData.set(i.productId, i.data); });
                setProductsData(newProductData);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (_jsx(ProductDataContext.Provider, __assign({ value: {
            productsData: productsData
        } }, { children: productsData.size > 0 && children })));
};
function useProductDataState() {
    var context = useContext(ProductDataContext);
    if (!context) {
        throw new Error('no provider for ProductDataState');
    }
    return context;
}
;
export { ProductDataProvider, useProductDataState };
