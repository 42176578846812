import { createTheme } from '@mui/material/styles';
import { alpha, darken, lighten } from '@mui/material';
import './App.css';
// space between sections on a page
export var sectionSpace = '2.5rem';
var trovioDark = '#1d201c';
var trovioDarker = '#151815';
var trovioDarkLighter = '#2b302a';
var trovioOrange = '#f8834d';
var trovioBeige = '#e2e8c0';
// const trovioBlue = '#9ba7c0';
// const trovioLavender = '#cbc5ea';
var trovioGreen = '#71b771';
// const trovioTeal = '#a0d1c8';
var defaultBlue = '#1975D2';
// use these typographic variants, as directed
var typographyOptions = {
    fontFamily: 'PPPangramSans, Roboto, Arial',
    h2: {
        // page heading
        fontSize: '1.188rem' // =19px
    },
    h3: {
        // sub heading
        fontSize: '0.938rem' // =15px
    },
    body1: {
        // body
        fontSize: '0.875rem' // =14px
    }
};
export var defaultTheme = {
    theme: createTheme({
        typography: typographyOptions,
        components: {
            MuiAppBar: {
                defaultProps: {
                    variant: 'elevation',
                    color: 'default'
                }
            },
            MuiTypography: {
                styleOverrides: {
                    h2: {
                        fontWeight: 'bold',
                        marginBottom: '2rem'
                    },
                    h3: {
                        fontWeight: 'bold',
                        marginBottom: '0.5rem'
                    }
                }
            }
        }
    }),
    customProps: {
        name: 'default',
        nestedTableBackgroundColors: ['#ddd', '#ccc', '#bbb'],
        inputFieldBackground: 'default',
        navHoverBackgroundColor: alpha(defaultBlue, 0.3),
        navBarHoverBackgroundColor: alpha(defaultBlue, 0.3),
        navBarText: '#ffffff',
        navHoverTransition: 'background-color 0.2s ease'
    }
};
export var trovioDarkTheme = {
    theme: createTheme({
        typography: typographyOptions,
        palette: {
            mode: 'dark',
            primary: {
                main: trovioOrange
            },
            secondary: {
                main: trovioBeige
            },
            background: {
                default: trovioDark,
                paper: trovioDarker
            },
            text: {
                primary: trovioBeige
            },
            success: {
                main: trovioGreen
            }
        },
        components: {
            MuiTypography: {
                styleOverrides: {
                    h2: {
                        fontWeight: 'bold',
                        marginBottom: '2rem'
                    },
                    h3: {
                        fontWeight: 'bold',
                        marginBottom: '0.5rem'
                    }
                }
            }
        }
    }),
    customProps: {
        name: 'trovio-dark',
        nestedTableBackgroundColors: ['#444', '#555', '#666'],
        inputFieldBackground: trovioDarkLighter,
        navHoverBackgroundColor: alpha(trovioOrange, 0.2),
        navBarHoverBackgroundColor: alpha(trovioOrange, 0.2),
        navBarText: trovioBeige,
        navBarTextSelected: trovioOrange,
        navHoverTransition: 'background-color 0.2s ease'
    }
};
export var trovioLightTheme = {
    theme: createTheme({
        typography: typographyOptions,
        palette: {
            mode: 'light',
            primary: {
                main: trovioOrange
            },
            secondary: {
                main: trovioDark
            },
            background: {
                default: lighten(trovioBeige, 0.8),
                paper: lighten(trovioBeige, 0.7)
            },
            text: {
                primary: trovioDark,
                secondary: trovioDarker,
                disabled: trovioDark
            },
            success: {
                main: trovioGreen
            }
        },
        components: {
            MuiAppBar: {
                defaultProps: {
                    variant: 'elevation',
                    // color: 'secondary'
                },
                styleOverrides: {
                    colorSecondary: {
                        backgroundColor: trovioBeige
                    }
                }
            },
            MuiTypography: {
                styleOverrides: {
                    h2: {
                        fontWeight: 'bold',
                        marginBottom: '2rem'
                    },
                    h3: {
                        fontWeight: 'bold',
                        marginBottom: '0.5rem'
                    }
                }
            }
        }
    }),
    customProps: {
        name: 'trovio-light',
        nestedTableBackgroundColors: [lighten(trovioBeige, 0.5), lighten(trovioBeige, 0.4), lighten(trovioBeige, 0.3)],
        inputFieldBackground: lighten(trovioBeige, 0.8),
        navHoverBackgroundColor: alpha(trovioOrange, 0.2),
        navBarHoverBackgroundColor: alpha(trovioOrange, 0.2),
        navBarText: trovioDark,
        navBarTextSelected: trovioOrange,
        navHoverTransition: 'background-color 0.2s ease'
    }
};
// const white = '#ffffff';
var cms1 = '#281B39';
var cms2 = '#2F147A';
// const cms3 = '#4720B7';
var cms4 = '#40C0F2';
var cms5 = '#70F0A6';
// const cms6 = '#B0F0CB';
// const cms7 = '#D1F8E0';
export var cmsTheme = {
    theme: createTheme({
        typography: typographyOptions,
        palette: {
            mode: 'light',
            primary: {
                main: darken(cms4, 0.2)
            },
            secondary: {
                main: cms2
            },
            background: {
                paper: '#ffffff',
                default: lighten(cms4, 0.9)
            },
            text: {
                primary: cms1
            },
            success: {
                main: cms5
            }
        },
        components: {
            MuiAppBar: {
                defaultProps: {
                    variant: 'elevation',
                    color: 'secondary'
                }
            },
            MuiTypography: {
                styleOverrides: {
                    h2: {
                        fontWeight: 'bold',
                        marginBottom: '2rem'
                    },
                    h3: {
                        fontWeight: 'bold',
                        marginBottom: '0.5rem'
                    }
                }
            }
        }
    }),
    customProps: {
        name: 'cms',
        darkHeader: true,
        nestedTableBackgroundColors: ['#eee', '#ddd', '#ccc'],
        inputFieldBackground: lighten(cms4, 0.8),
        navHoverBackgroundColor: alpha(cms4, 0.3),
        navBarHoverBackgroundColor: alpha(cms4, 0.3),
        navBarText: cms1,
        navBarTextSelected: '#ffffff',
        navHoverTransition: 'background-color 0.2s ease'
    }
};
var anzDarkBlue = '#004165';
var anzMidBlue = '#007dba';
var anzLightBlue = '#0093d8';
var anzWhite = '#ffffff';
var anzBackgroundBlue = '#EEF2F6';
export var anzTheme = {
    theme: createTheme({
        typography: typographyOptions,
        palette: {
            mode: 'light',
            primary: {
                main: anzDarkBlue
            },
            secondary: {
                main: anzLightBlue
            },
            background: {
                paper: anzBackgroundBlue,
                default: anzWhite
            },
            text: {
                primary: anzDarkBlue
            },
            success: {
                main: '#00AC6C'
            }
        },
        components: {
            MuiAppBar: {
                defaultProps: {
                    variant: 'elevation',
                }
            },
            MuiTypography: {
                styleOverrides: {
                    h2: {
                        fontWeight: 'bold',
                        marginBottom: '2rem'
                    },
                    h3: {
                        fontWeight: 'bold',
                        marginBottom: '0.5rem'
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderColor: anzMidBlue,
                        color: anzMidBlue,
                        "&:hover": {
                            borderColor: anzLightBlue,
                            color: anzLightBlue,
                        },
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        color: '#0072ac',
                        "&:hover": {
                            color: anzLightBlue
                        },
                    },
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        backgroundColor: anzWhite,
                        color: anzMidBlue,
                        "&:hover": {
                            backgroundColor: lighten(anzLightBlue, 0.9),
                        },
                    }
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        "& .MuiTableRow-root": {
                            backgroundColor: anzBackgroundBlue
                        },
                    },
                }
            },
            MuiTableBody: {
                styleOverrides: {
                    root: {
                        "& .MuiTableRow-root": {
                            "&:nth-of-type(odd)": {
                                backgroundColor: anzWhite
                            },
                            "&:nth-of-type(even)": {
                                backgroundColor: anzBackgroundBlue
                            },
                        },
                    }
                },
            },
        }
    }),
    customProps: {
        name: 'anz',
        darkHeader: true,
        nestedTableBackgroundColors: ['#eee', '#ddd', '#ccc'],
        inputFieldBackground: anzWhite,
        // Secondary nav bars (ie. StyledTabs component)
        navTextHover: anzWhite,
        navHoverBackgroundColor: anzLightBlue,
        // Primary Nav Bar
        navBarHoverBackgroundColor: anzLightBlue,
        navBarText: anzWhite,
        navBarTextSelected: anzWhite,
        navHoverTransition: 'background-color 0.2s ease',
    }
};
export var appThemes = {
    anz: anzTheme,
    cms: cmsTheme,
    'trovio-dark': trovioDarkTheme,
    'trovio-light': trovioLightTheme,
    material: defaultTheme
};
