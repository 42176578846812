import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography, } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { submitUnsignedTransaction, useAuth, useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';
import dayjs from 'dayjs';
import { DeleteProductItemRequest, DeleteProductItemRequestDeflationModeEnum } from '@trovio-tech/trovio-core-api-js';
import { 
    AlertDialogContent,
    AlertType,
    DATE_DISPLAY_FORMAT,
    getFieldDefinitionMap,
    inventoryAccount,
    TransactionOverview,
    useAppConfigState,
    useProductDataState,
    useSubmitTransaction
 } from '@commodity-desk/common';

const ForwardTradeCancelDialog = ({
    dialogActive,
    forwardTradeRow,
    onActionTaken,
    onClose
}: {
    dialogActive: boolean;
    forwardTradeRow: any;
    onActionTaken: () => void;
    onClose: () => void;
}) => {

    const appConfigState = useAppConfigState();
    const user = useAuth();
    const { cortenAuthApi } = useCortenApiState();

    const [transactionSuccess, setTransactionSuccess] = useState<boolean>(false);
    const [transactionErrorMessage, setTransactionErrorMessage] = useState<string>("");
    const [transactionErrorCode, setTransactionErrorCode] = useState<string>("");
    const [transactionWarnMessage, setTransactionWarnMessage] = useState<string>("");
    const [isTransactionInProgress, setIsTransactionInProgress] = useState<boolean>(false);
    const pendingTransaction = useRef<string | null>(null);

    const { productsData } = useProductDataState();
    const fieldDefinitionMap = getFieldDefinitionMap({appConfigState: appConfigState, productsData: productsData});

    useEffect(() => {
        if (dialogActive) {
            setIsTransactionInProgress(false);
            pendingTransaction.current = null;
            setTransactionSuccess(false);
        }
    }, [dialogActive]);

    const { submitAsync, transactionState, resetTransactionState } = useSubmitTransaction();

    useEffect(() => {
        if (transactionState.status === "EXECUTED") {
            setIsTransactionInProgress(false);
            setTransactionSuccess(true);
            onActionTaken();
        } else if (transactionState.status === "FAILED") {
            setIsTransactionInProgress(false);
            setTransactionErrorMessage('An error occurred when canceling the forward trade.');
            setTransactionErrorCode(transactionState.transactionErrorCode);
        }
    }, [transactionState]);

    const handleDialogClose = () => {
        resetTransactionState();
        setIsTransactionInProgress(false);
        pendingTransaction.current = null;
        setTransactionSuccess(false);
        // update state in the parent view
        onClose();
    }

    const performTransaction = async () => {
        setIsTransactionInProgress(true);
        const deleteProductItemRequest: DeleteProductItemRequest = {
            type: "DeleteProductItemRequest",
            issuerId: inventoryAccount.id,
            productItemId: forwardTradeRow.forwardId,
            deflationMode: DeleteProductItemRequestDeflationModeEnum.DeflateAndDelete,
            nonce: new Date().getTime()
        };
        submitAsync(deleteProductItemRequest);
    };

    if (transactionSuccess) {
        return (
            <TransactionOverview
                open={transactionSuccess}
                onClose={handleDialogClose}
                title='Forward Trade Canceled Successfully'
                uiElements={{
                    'transactionId': { value: pendingTransaction.current! }
                }}
                fieldDefinitionMap={fieldDefinitionMap}
                productType={undefined}
            />
        );
    }

    return (
        <Dialog open={dialogActive} onClose={onClose} fullWidth maxWidth="xs">

            {!transactionSuccess && !transactionErrorMessage && !transactionWarnMessage && (
                <>
                    <DialogContent>
                        <Typography variant='h2'>
                            Confirm Cancelation of Forward Trade
                        </Typography>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '1rem' }}>
                            <Typography>
                                Forward ID:
                            </Typography>
                            <Box sx={{  width: '20em', textAlign: 'right', flex: '1'}}>
                                <Typography>{forwardTradeRow.forwardId}</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '1rem' }}>
                            <Typography>
                                Counterparty:
                            </Typography>
                            <Box sx={{  width: '20em', textAlign: 'right', flex: '1'}}>
                                <Typography>{forwardTradeRow.counterParty}</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '2rem' }}>
                            <Typography>
                                Value Date:
                            </Typography>
                            <Box sx={{  width: '20em', textAlign: 'right', flex: '1'}}>
                                <Typography>{dayjs(forwardTradeRow.valueDate).format(DATE_DISPLAY_FORMAT)}</Typography>
                            </Box>
                        </Box>

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary" variant="outlined">
                            Go Back
                        </Button>
                        <Button
                            onClick={() => performTransaction()}
                            color="primary"
                            variant="outlined"
                        >
                            {isTransactionInProgress ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '2px'
                                    }}
                                >
                                    <CircularProgress size={20} />
                                </Box>
                            ) : (
                                'Confirm Cancelation'
                            )}
                        </Button>
                    </DialogActions>
                </>
            )}
            {transactionErrorMessage && (
                <AlertDialogContent
                    alertType={AlertType.Error}
                    alertMessage={transactionErrorMessage}
                    errorCode={transactionErrorCode}
                    handleDialogClose={onClose}
                />
            )}
            {transactionWarnMessage && !transactionErrorMessage && (
                <AlertDialogContent
                    alertType={AlertType.Warning}
                    alertMessage={transactionWarnMessage}
                    errorCode={null}
                    handleDialogClose={onClose}
                />
            )}
        </Dialog>

    );
};

export { ForwardTradeCancelDialog };
