import {
    createContext,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useContext,
    useEffect,
    useRef,
    useState
} from 'react';
import { inventoryAccount } from '@commodity-desk/common';
import { useProductState } from './ProductState';
import {
    EscrowTransactionData,
    ListEscrowRequest,
    PagedListResponseEscrowTransactionData
} from '@trovio-tech/trovio-core-api-js';
import {  useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';
import { usePagination } from '@trovio-ui-libs/ui';
import { PaginationState } from '@tanstack/react-table';

type State = {
    isLoading: boolean;
    data: EscrowTransactionData[];
    total: number;
    pagination: PaginationState;
};

type Actions = {
    getNextPageToken: (pageIndex: number) => string | undefined;
    onSuccess: (itemId: string) => void;
    onPaginationChange: Dispatch<SetStateAction<PaginationState>>;
    resetTable: () => void;
};

const LockedHoldingContext = createContext<(State & Actions) | null>(null);

/**
 * This Provider  defines the state and actions for displaying a list of locked holdings for a specific account and product.
 * The state includes information about the loading status, the data to be displayed, and the pagination settings.
 * The actions include handling pagination changes, onSuccess and fetchLockedHoldings function.
 * The state and actions are as follows: 
 * isLoading - The loading status of the data
 * data - The data to be displayed
 * total - The total number of items
 * pagination - The current PaginationState of the table
 * getNextPageToken - A function to retrieve the 'next page' token for any previously accessed page
 * onSuccess - The function that need to be trigger after onSuccess
 * onPaginationChange - pagination hook exported from the DataTable library.
 * resetTable - A function to reset the table back to the first page
 */
const LockedHoldingsProvider = ({ children }: PropsWithChildren) => {
    const { mblSelectedProduct } = useProductState();
    const mapPageToNextCursor = useRef<{ [page: number]: string | undefined }>({});
    const PAGE_SIZE = 20; // Hardcoded to 20 for now
    const { pagination, onPaginationChange } = usePagination(PAGE_SIZE);

    const { cortenAuthApi } = useCortenApiState()

    const [state, setState] = useState<{
        isLoading: boolean;
        data: EscrowTransactionData[];
        total: number;
    }>({
        isLoading: false,
        data: [],
        total: 0
    });

    const loadData = async (pageIndex: number): Promise<PagedListResponseEscrowTransactionData | undefined> => {
        try {
            const nextPage = getNextPageToken(pageIndex);

            // Creating URL params
            const params = new URLSearchParams();
            params.set('holderAccountId', inventoryAccount.id);
            params.set('productId', mblSelectedProduct.id);
            params.set('page.withCount', 'true');

            // page.from param
            if (nextPage) params.set('page.from', nextPage);

            // This is required to get  actual value for the product with ":",
            const array: any = [];
            params.forEach((value, key) => {
                array.push(`${key}=${value}`);
            });

            let listEscrowRequest: ListEscrowRequest = {
                holderAccountId: inventoryAccount.id,
                productId: mblSelectedProduct.id,
                pageWithCount: true,
                pageFrom: nextPage,
                pageLimit: PAGE_SIZE
            }
            return cortenAuthApi!!.account.listEscrow(listEscrowRequest);
        } catch (error) {}
        return undefined;
    };

    const getNextPageToken = (pageIndex: number): string | undefined => {
        return mapPageToNextCursor.current[pageIndex];
    }

    // On Success : Function to be called after a successful unlocked transaction
    const onSuccess = async (itemId: string | undefined) => {
        if (!itemId) return;
        resetTable();
    };

    const fetchLockedHoldings = async (pageIndex: number) => {
        setState((prev) => ({ ...prev, isLoading: true }));
        const res = await loadData(pageIndex - 1);
        if (res) {
            mapPageToNextCursor.current[pageIndex] = res.nextPage;

            // To handle pagination  data
            setState((prev) => ({
                ...prev,
                total: res.count ?? 0,
                data: res.list || [],
                isLoading: false
            }));
        } else {
            setState((prev) => ({ ...prev, isLoading: false }));
        }
    };

    useEffect(() => {
        fetchLockedHoldings(pagination.pageIndex).then();
    }, [pagination]);

    const resetTable = () => {
        onPaginationChange({ pageIndex: 0, pageSize: PAGE_SIZE });
    }

    return (
        <LockedHoldingContext.Provider
            value={{
                isLoading: state.isLoading,
                data: state.data,
                total: state.total,
                pagination: pagination,
                onPaginationChange: onPaginationChange,
                getNextPageToken,
                onSuccess,
                resetTable
            }}
        >
            {children}
        </LockedHoldingContext.Provider>
    );
};

/**
 * This hooks provides LockedHoldingContext which defines the state and
 * actions for displaying a list of locked holdings for a specific account and product.
 * @param isLoading - The loading status of the data
 * @param data - The data to be displayed
 * @param total - The total number of items
 * @param pagnation - The current PaginationState of the table
 * @param getNextPageToken - A fucntion that returns the stored 'next page' token for any previously accessed page
 * @param onSuccess - The function that need to be trigger after onSuccess
 * @param onPaginationChange - pagination hook exported from the DataTable library.
 * @param resetTable - A function to reset the table back to the first page
 */
const useLockedHoldings = () => {
    const context = useContext(LockedHoldingContext);
    if (!context) {
        throw new Error('useLockedHoldings must be used within a LockedHoldingsProvider');
    }
    return { ...context };
};

export { LockedHoldingsProvider, useLockedHoldings };
