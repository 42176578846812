import { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
    HoldingRetirement,
    inventoryAccount,
    ProductType,
    StyledTabs,
    useAppConfigState,
    useCommodityDeskApiContext,
    useLayoutState,
    useProductDataState,
    transactionAttributes
} from '@commodity-desk/common';
import { useAuth, useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';
import { HoldingLockToMarket } from '../../component/HoldingLockToMarket';
import { HoldingPageType, Holdings } from "../../component/Holdings";
import LockedHoldings from './LockedHoldings';
import { ProductOption, useProductState } from "./state/ProductState";
import { TradePageType, Trades } from "../../component/Trades";
import { useLockedHoldings } from './state/LockedHoldingState';
import { HoldingDataStateEnum } from "@trovio-tech/trovio-core-api-js";
import { UnlockHoldings } from "./UnlockHoldings";

const Certificates = ({ product }: { product: ProductOption }) => {

    const { mblSelectedProduct, fetchAndInitProducts } = useProductState();
    const appConfigState = useAppConfigState();
    const { productsData } = useProductDataState();
    const [tabValue, setTabValue] = useState<'Certificates' | 'LockedHoldings' | 'ForwardTrades'>('Certificates');
    const { resetTable } = useLockedHoldings();
    const { customTheme } = useLayoutState();
    const [retirementDialogActive, setRetirementDialogActive] = useState(false);
    const [lockToMarketDialogActive, setLockToMarketDialogActive] = useState(false);
    const [unlockHoldingsDialogActive, setUnlockHoldingsDialogActive] = useState(false);
    const [selectedHoldings, setSelectedHoldings] = useState<any[]>([]);
    const [holdingRefreshSignal, setHoldingRefreshSignal] = useState<number | any>(0);

    const user = useAuth();
    const { commodityDeskApi } = useCommodityDeskApiContext();
    const { cortenAuthApi } = useCortenApiState();

    const openRetirementDialog = () => {
        setRetirementDialogActive(true);
    };

    const onRetirementDialogClosed = () => {
        setSelectedHoldings([]);
        setHoldingRefreshSignal(holdingRefreshSignal + 1);
        setRetirementDialogActive(false);
        fetchAndInitProducts(mblSelectedProduct.id);
    };

    const openLockToMarketDialog = () => {
        setLockToMarketDialogActive(true);
    };

    const onLockToMarketDialogClosed = () => {
        setSelectedHoldings([]);
        setHoldingRefreshSignal(holdingRefreshSignal + 1);
        setLockToMarketDialogActive(false);
        fetchAndInitProducts(mblSelectedProduct.id);
        resetTable();
    };

    const openUnlockHoldingsDialog = () => {
        setUnlockHoldingsDialogActive(true);
    };

    const onUnlockHoldingsDialogClosed = () => {
        setUnlockHoldingsDialogActive(false);
        setSelectedHoldings([]);
        setHoldingRefreshSignal(holdingRefreshSignal + 1);
        fetchAndInitProducts(mblSelectedProduct.id);
        resetTable();
    };

    const onHoldingSelectionUpdated = (holdings: any[]) => {
        setSelectedHoldings(holdings);
    };

    return (
        <Box style={{ marginBottom: '5rem' }}>
            <Typography variant="h3">
                {
                    product.code === ProductType.LGC ? 'Certificates and Forward Trades'
                    : product.code === ProductType.MiQC ? 'Certificates, Locked Holdings and Forward Trades'
                    : 'Certificates'
                }
            </Typography>
            <Stack direction="row" spacing={2} marginBottom={1} alignItems="baseline">
                <Button
                    variant="outlined"
                    onClick={openRetirementDialog}
                    disabled={selectedHoldings.some(holding => holding.state !== HoldingDataStateEnum.Unspent)}
                >
                    Retire
                </Button>
                {retirementDialogActive && (
                    <HoldingRetirement
                        useDialog={true}
                        accountId={inventoryAccount.id}
                        holdings={selectedHoldings}
                        preSelectedProductId={product?.id}
                        retirementDialogActive={retirementDialogActive}
                        onRetirementDialogClosed={onRetirementDialogClosed}
                        productsData={productsData}
                        customTheme={customTheme}
                        appConfigState={appConfigState}
                        user={user}
                        commodityDeskApi={commodityDeskApi}
                        cortenAuthApi={cortenAuthApi}
                    />
                )}
                <Button
                    variant="outlined"
                    onClick={openLockToMarketDialog}
                >
                    Lock to Market
                </Button>
                {lockToMarketDialogActive && (
                    <HoldingLockToMarket
                        lockToMarketDialogActive={lockToMarketDialogActive}
                        preSelectedProductId={product.id}
                        onLockToMarketDialogClosed={onLockToMarketDialogClosed}
                    />
                )}
                <Button
                    disabled={selectedHoldings.some(holding => holding.state !== HoldingDataStateEnum.Escrowed)}
                    variant="outlined"
                    onClick={openUnlockHoldingsDialog}
                >
                    Unlock from Market
                </Button>
                {unlockHoldingsDialogActive && (
                    <UnlockHoldings
                        unlockHoldingsDialogActive={unlockHoldingsDialogActive}
                        preSelectedProductId={product.id}
                        onUnlockHoldingsDialogClosed={onUnlockHoldingsDialogClosed}
                        holdings={selectedHoldings}
                    />
                )}
            </Stack>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
                <StyledTabs
                    value={tabValue}
                    onChange={(_event: React.SyntheticEvent, value: any): void => {
                        // If we change to the Locked Holdings Tab for MiQC then reset the selected holdings
                        if (product.code === ProductType.MiQC && value === 1) {
                            setSelectedHoldings([]);
                        }
                        setTabValue(value);
                    }}
                    tabs={product.code === ProductType.LGC ? [
                        { label: "Certificates", value: 'Certificates' },
                        { label: "Forward Trades", value: 'ForwardTrades' }
                    ] : product.code === ProductType.MiQC ? [
                        { label: "Certificates", value: 'Certificates' },
                        { label: "Locked Holdings", value: 'LockedHoldings' },
                        { label: "Forward Trades", value: 'ForwardTrades' }
                    ] : [
                        { label: "Certificates", value: 'Certificates' }
                    ]}
                    customTheme={customTheme}
                />
            </Box>

            {tabValue === 'Certificates' && (
                <Holdings
                    pageType={HoldingPageType.INVENTORY_CERTIFICATES}
                    columnDefinitions={[
                        ...(product.code === ProductType.LGC ? [
                            {key: 'selectbox', showByDefault: true},
                            {key: 'accreditationCode', showByDefault: true},
                            {key: 'fuelSource', showByDefault: true},
                            {key: 'creationYear', showByDefault: true},
                            {key: 'generationYear', showByDefault: true},
                            {key: 'generationState', showByDefault: true},
                            {key: 'serialNumRange', showByDefault: true},
                            {key: 'amount', showByDefault: true},
                            {key: 'state', showByDefault: true},
                            {key: 'greenPowerAccredited', showByDefault: true},
                        ] : []),
                        ...(product.code === ProductType.MiQC ? [
                            {key: 'selectbox', showByDefault: true},
                            {key: 'holdingId', showByDefault: true},
                            {key: "facility", showByDefault: true},
                            {key: "segment", showByDefault: true},
                            {key: "issueMonth", showByDefault: true},
                            {key: "issueYear", showByDefault: false},
                            {key: "countryOfOperation", showByDefault: true},
                            {key: "certificateRegion", showByDefault: false},
                            {key: "operatorName", showByDefault: false},
                            {key: "miqMethaneIntensity", showByDefault: true},
                            {key: "miqGrade", showByDefault: true},
                            {key: "miqGradeStatus", showByDefault: true},
                            {key: "miqAuditorName", showByDefault: false},
                            {key: "eo100Grade", showByDefault: true},
                            {key: 'amount', showByDefault: true},
                            {key: 'state', showByDefault: true},
                        ] : []),
                    ]}
                    pageFilters={{
                        accountId: inventoryAccount.id,
                        product: product.id,
                    }}
                    selectedHoldings={selectedHoldings}
                    onHoldingSelectionUpdated={onHoldingSelectionUpdated}
                    showDialogOnRowClick={true}
                    refreshSignal={holdingRefreshSignal}
                />
            )}
            {tabValue === 'LockedHoldings' && <LockedHoldings />}
            {tabValue === 'ForwardTrades' && (
                <Trades
                    pageType={TradePageType.Forwards}
                    columnDefinitions={[
                        {key: "valueDate", showByDefault: true},
                        {key: "tradeDate", showByDefault: false},
                        {key: "forwardId", showByDefault: true},
                        {key: "tradeId", showByDefault: true},
                        {key: "quantity", showByDefault: true},
                        {key: "productId", showByDefault: true},
                        ...(product.code === ProductType.LGC ? [
                            {key: "accreditationCode", showByDefault: false},
                            {key: "fuelSource", showByDefault: true},
                            {key: "creationYear", showByDefault: false},
                            {key: "generationYear", showByDefault: true},
                            {key: "generationState", showByDefault: false},
                            {key: "greenPowerAccredited", showByDefault: false},
                        ] : []),
                        ...(product.code === ProductType.MiQC ? [
                            {key: "segment", showByDefault: true},
                            {key: "issueMonth", showByDefault: false},
                            {key: "issueYear", showByDefault: true},
                            {key: "countryOfOperation", showByDefault: false},
                            {key: "certificateRegion", showByDefault: false},
                            {key: "operatorName", showByDefault: false},
                            {key: "miqMethaneIntensity", showByDefault: false},
                            {key: "miqGrade", showByDefault: true},
                            {key: "miqGradeStatus", showByDefault: false},
                            {key: "miqAuditorName", showByDefault: false},
                            {key: "eo100Grade", showByDefault: false},
                        ] : []),
                        {key: "currency", showByDefault: true},
                        {key: "price", showByDefault: true},
                        {key: "counterParty", showByDefault: true},
                        {key: "trader", showByDefault: false},
                        {key: "salesPerson", showByDefault: false},
                        {key: "salesCredits", showByDefault: false},
                        {key: "brokerName", showByDefault: false},
                        {key: "brokerage", showByDefault: false},
                    ]}
                    defaultOrdering={`productItemAttribute.${transactionAttributes.valueDate.key}`}
                    pageFilters={{product: mblSelectedProduct.id}}
                />
            )}
        </Box>
    );
}

export default Certificates;
