import { Box, Button, LinearProgress, Stack, Typography } from '@mui/material';
import { useProjectDetailsDispatch, useProjectDetailsState } from './state/ProjectDetailsState';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { HoldingPageType, Holdings } from '../../component/Holdings';
import Vintages from './Vintages';
import { SyntheticEvent, useEffect, useState } from 'react';
import { HoldingsSummary } from './HoldingsSummary';
import ProjectAttributes from './ProjectAttributes';
import {
    fetchProductAttributesWithId,
    HoldingRetirement,
    inventoryAccount,
    carbonProjectAttributes,
    StyledTabs,
    sectionSpace,
    useAppConfigState,
    useLayoutState,
    useProductDataState,
    useCommodityDeskApiContext,
} from '@commodity-desk/common';
import { useAuth, useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';
import { HoldingDataStateEnum, ProductData } from '@trovio-tech/trovio-core-api-js';
import Error from '../../component/Error';
import { HoldingLockToMarket } from '../../component/HoldingLockToMarket';
import { UnlockHoldings } from './UnlockHoldings';

const ProjectDetails = () => {
    const appConfigState = useAppConfigState();
    const { productsData } = useProductDataState();
    const { customTheme } = useLayoutState();
    const user = useAuth();
    const { cortenAuthApi } = useCortenApiState();

    const { projectBalances, handleProjectDetailsView, selectedIds, loading, selectedProjectDetails, fetchProjectBalanceAndDetails, isPathError } =
        useProjectDetailsState();
    const { setIsPathError } = useProjectDetailsDispatch()

    // sub tabs / nested routes
    const [tabValue, setTabValue] = useState(0);
    const [retirementDialogActive, setRetirementDialogActive] = useState(false);
    const [lockToMarketDialogActive, setLockToMarketDialogActive] = useState(false);
    const [unlockHoldingsDialogActive, setUnlockHoldingsDialogActive] = useState(false);
    const [currentProduct, setCurrentProduct] = useState<ProductData | undefined>(undefined);
    const [selectedHoldings, setSelectedHoldings] = useState<any[]>([]);
    const [holdingRefreshSignal, setHoldingRefreshSignal] = useState<number | any>(0);
    const { commodityDeskApi } = useCommodityDeskApiContext();

    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
        // If we change to the Vintages Tab then reset the selected holdings
        if (newValue === 1) {
            setSelectedHoldings([]);
        }
        setTabValue(newValue);
    };

    // extract productID & projectID from url for use in fetch requests for this view
    // incase we input url directly in browser rather than come from a double click on the previous page
    const location = useLocation();
    const pathname = location.pathname;
    const splitLocation = pathname.split('/');
    const productID = splitLocation[4];
    const projectID = splitLocation[6];
    const subTab = splitLocation[7];

    // select the sub tab based on url
    useEffect(() => {
        // set nested tabs according to url
        if (subTab === 'holdings') {
            setTabValue(0);
        }
        if (subTab === 'vintages') {
            setTabValue(1);
        }
    }, [subTab]);

    // entry point for view
    useEffect(() => {
        // clear path error
        setIsPathError(false)

        // check that product ID is valid before making any requests
        if (productID && appConfigState.getProducts(true).map(p => p.id).includes(productID)) {
            // set global state for productID and projectID
            selectedIds.current = {
                productID: productID,
                projectID: projectID,
                vintage: ''
            };

            handleProjectDetailsView();
            fetchProductAttributesWithId(productID, cortenAuthApi).then((foundProduct) => {
                setCurrentProduct(foundProduct);
            });
        } else {
            // show an error
            setIsPathError(true)
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const openRetirementDialog = () => {
        setRetirementDialogActive(true);
    };

    const onRetirementDialogClosed = () => {
        setSelectedHoldings([]);
        setHoldingRefreshSignal(holdingRefreshSignal + 1);
        setRetirementDialogActive(false);
        fetchProjectBalanceAndDetails([{ code: carbonProjectAttributes.projectId.key, value: selectedIds.current.projectID }]);
    };

    const openLockToMarketDialog = () => {
        setLockToMarketDialogActive(true);
    };

    const onLockToMarketDialogClosed = () => {
        setSelectedHoldings([]);
        setHoldingRefreshSignal(holdingRefreshSignal + 1);
        setLockToMarketDialogActive(false);
        fetchProjectBalanceAndDetails([{ code: carbonProjectAttributes.projectId.key, value: selectedIds.current.projectID }]);
    };

    const openUnlockHoldingsDialog = () => {
        setUnlockHoldingsDialogActive(true);
    };

    const onUnlockHoldingsDialogClosed = () => {
        setUnlockHoldingsDialogActive(false);
        setSelectedHoldings([]);
        setHoldingRefreshSignal(holdingRefreshSignal + 1);
        fetchProjectBalanceAndDetails([{ code: carbonProjectAttributes.projectId.key, value: selectedIds.current.projectID }]);
    };

    const onHoldingSelectionUpdated = (holdings: any[]) => {
        setSelectedHoldings(holdings);
    };

    return (
        <>
            <Typography variant="h2">
                Project Details
            </Typography>

            {
                isPathError ?
                    <Error message='Error - Product ID not recognised' />
                    :
                    <>
                        {loading ? (
                            <LinearProgress />
                        ) : (
                            <>
                                {/* HOLDINGS *********************/}
                                {projectBalances && Object.keys(projectBalances).length > 0 ? (
                                    <HoldingsSummary
                                        issuerAmount={projectBalances?.issuerAmount}
                                        assignedAmount={projectBalances?.assignedAmount}
                                        unassignedAmount={projectBalances?.unassignedAmount}
                                        escrowAmount={projectBalances?.escrowAmount}
                                        title="Project Holding Summary"
                                        minDecimalPos={productsData.get(productID)?.minDecimalPos!}
                                        maxDecimalPos={productsData.get(productID)?.maxDecimalPos!}
                                    />
                                ) : (
                                    <Box sx={{ marginBottom: sectionSpace }}>
                                        <Typography variant='h3'>
                                            Project Holding Summary
                                        </Typography>
                                        <Typography>No holding summary to display</Typography>
                                    </Box>
                                )}
                                {/* PROJECT ATTRIBUTES *********************/}
                                <ProjectAttributes
                                    title='Project Summary'
                                    showVintage={false}
                                    vintage=''
                                />

                                {/* HOLDINGS & VINTAGE *********************/}
                                <Typography variant='h3'>
                                    Holdings and Vintages
                                </Typography>

                                <Stack direction="row" spacing={2} marginBottom={0} alignItems="baseline">
                                    <Button
                                        variant='outlined'
                                        onClick={openRetirementDialog}
                                        disabled={currentProduct === undefined || selectedHoldings.some(holding => holding.state !== HoldingDataStateEnum.Unspent)}
                                    >Retire</Button>
                                    {retirementDialogActive && (
                                        <HoldingRetirement
                                            useDialog={true}
                                            accountId={inventoryAccount.id}
                                            holdings={selectedHoldings}
                                            preSelectedProductId={currentProduct?.productId}
                                            preSelectedProjectId={projectID}
                                            preSelectedProjectType={selectedProjectDetails?.projectType}
                                            retirementDialogActive={retirementDialogActive}
                                            onRetirementDialogClosed={onRetirementDialogClosed}
                                            productsData={productsData}
                                            customTheme={customTheme}
                                            appConfigState={appConfigState}
                                            user={user}
                                            commodityDeskApi={commodityDeskApi}
                                            cortenAuthApi={cortenAuthApi}
                                        />
                                    )}
                                    <Button disabled={currentProduct === undefined} variant='outlined' onClick={openLockToMarketDialog} >Lock to Market</Button>
                                    {lockToMarketDialogActive && (
                                        <HoldingLockToMarket
                                            lockToMarketDialogActive={lockToMarketDialogActive}
                                            preSelectedProductId={currentProduct?.productId}
                                            preSelectedProjectId={projectID}
                                            preSelectedProjectType={selectedProjectDetails?.projectType}
                                            onLockToMarketDialogClosed={onLockToMarketDialogClosed}
                                        />
                                    )}
                                    <Button
                                        disabled={currentProduct === undefined || selectedHoldings.some(holding => holding.state !== HoldingDataStateEnum.Escrowed)}
                                        variant="outlined"
                                        onClick={openUnlockHoldingsDialog}
                                    >
                                        Unlock from Market
                                    </Button>
                                    {unlockHoldingsDialogActive && (
                                        <UnlockHoldings
                                            unlockHoldingsDialogActive={unlockHoldingsDialogActive}
                                            preSelectedProductId={currentProduct!.productId}
                                            preSelectedProjectId={projectID}
                                            preSelectedProjectType={selectedProjectDetails?.projectType}
                                            onUnlockHoldingsDialogClosed={onUnlockHoldingsDialogClosed}
                                            holdings={selectedHoldings}
                                        />
                                    )}

                                </Stack>

                                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
                                    <StyledTabs
                                        value={tabValue}
                                        onChange={handleTabChange}
                                        tabs={[
                                            { label: "Holdings", value: 0, to: "holdings" },
                                            { label: "Vintages", value: 1, to: "vintages" }
                                        ]}
                                        customTheme={customTheme}
                                        linkComponent={Link}
                                    />
                                </Box>

                                <Routes>
                                    <Route index element={<Navigate to="holdings" replace />} />
                                    <Route
                                        path="/holdings"
                                        element={
                                            <Holdings
                                                pageType={HoldingPageType.PRODUCT_PROJECT_VINTAGE}
                                                columnDefinitions={[
                                                    { key: 'selectbox', showByDefault: true },
                                                    { key: 'holdingId', showByDefault: true },
                                                    { key: 'amount', showByDefault: true },
                                                    { key: 'vintage', showByDefault: true },
                                                    { key: 'serialNumRange', showByDefault: true },
                                                    { key: 'accountId', showByDefault: true },
                                                    { key: 'state', showByDefault: true },
                                                    { key: 'info', showByDefault: true }
                                                ]}
                                                pageFilters={{
                                                    product: productID,
                                                    project: projectID,
                                                }}
                                                selectedHoldings={selectedHoldings}
                                                onHoldingSelectionUpdated={onHoldingSelectionUpdated}
                                                refreshSignal={holdingRefreshSignal}
                                            />
                                        }
                                    />
                                    <Route path="/vintages" element={<Vintages />} />
                                </Routes>
                            </>
                        )}
                    </>
            }
        </>
    )
};

export default ProjectDetails;
