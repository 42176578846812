import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import {
    submitUnsignedTransaction,
    useAuth,
    useCortenApiState
} from '@trovio-tech/trovio-core-api-jsx';
import {
    DialogState,
    EmissionDisplay,
    EmissionTableType,
    useCommoditiesDispatch,
    useCommoditiesState
} from './CommoditiesState';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../utility/AppRoutes';
import { 
    EMISSION_ASSET_AMOUNT, 
    EMISSION_UNIT_AMOUNT,
    inventoryAccount,
    useAppConfigState,
    useSubmitTransaction
} from '@commodity-desk/common';
import { CreateProductItemRequest, ProductItemsAmount, SaveTransactionProofRequest } from '@trovio-tech/trovio-core-api-js';

interface TransactionErrorInfo {
    errorMessage: string;
    errorCode: string;
    warningMessage: string;
}

interface DecarboniseStateType {
    fetchItemsForDecarbonisedAsset: () => void;
    decarbonisedItems: any[];
    isDecarboniseFormOpen: boolean;
    handleDecarboniseFormOpen: (item: EmissionDisplay) => void;
    dialogState: DialogState;
    performDecarboniseEmissionAsset: (filters: any[]) => void;
    isRequesting: boolean;
    txId: string;
    resetTransactionState: () => void;
    errorInfo: TransactionErrorInfo;
    selectedPendingEmission: EmissionDisplay | undefined;
}

interface DecarboniseDispatchType {
    setIsDecarboniseFormOpen: Dispatch<SetStateAction<boolean>>;
    setDialogState: Dispatch<SetStateAction<DialogState>>;
    setTxId: Dispatch<SetStateAction<string>>;
    handleOpenCertificate: (productItemId: string) => void;
}

const DecarboniseStateContext = createContext<DecarboniseStateType | null>(null);
const DecarboniseDispatchContext = createContext<DecarboniseDispatchType | null>(null);

const DecarboniseProvider = ({ children }: { children?: ReactNode }) => {
    const appConfigState = useAppConfigState();
    const user = useAuth();
    const { cortenAuthApi } = useCortenApiState();
    const navigate = useNavigate();
    // const decarbProductId = appConfigState.getEmissionProductId('DECARBONISED_EMISSION_ASSET');

    const { setLoadingTable } = useCommoditiesDispatch();
    const [decarbonisedItems, setDecarbonisedItems] = useState<any>([]);
    const [isDecarboniseFormOpen, setIsDecarboniseFormOpen] = useState<boolean>(false);
    const [dialogState, setDialogState] = useState<DialogState>(DialogState.FORM);
    const [isRequesting, setIsRequesting] = useState<boolean>(false);
    const [txId, setTxId] = useState<string>('');
    const [errorInfo, setErrorInfo] = useState<TransactionErrorInfo>({
        errorMessage: '',
        errorCode: '',
        warningMessage: ''
    });
    const [selectedPendingEmission, setSelectedPendingEmission] = useState<
        EmissionDisplay | undefined
    >(undefined);

    // open form
    const handleDecarboniseFormOpen = (item: EmissionDisplay) => {
        // re-init form
        setDialogState(DialogState.FORM);

        setIsDecarboniseFormOpen(true);
        setSelectedPendingEmission(item);
    };

    // fetch product items for decarbonised emission product
    const fetchItemsForDecarbonisedAsset = async () => {
        // let combinedProductItems = await fetchProductItemsWithId(cortenAuthApi, decarbProductId);
        //
        // // create a display format from emission response data
        // if (combinedProductItems && combinedProductItems.length > 0) {
        //     let displayFormat = createEmissionsTableDisplay(combinedProductItems);
        //
        //     setDecarbonisedItems(displayFormat);
        // } else {
        //     setDecarbonisedItems([]);
        // }

        setDecarbonisedItems([]);
        setLoadingTable(false);
    };

    // POST request to create decarbonised product item
    const performDecarboniseEmissionAsset = async (itemIds: string[]) => {
        setIsRequesting(true);

        // calculate the amount for the carbon product using intensity and emission asset amount (hard coded)
        let totalAmount: number = 0;
        if (selectedPendingEmission?.intensity !== undefined) {
            // round decimal intensity values up to next whole number
            totalAmount = Math.ceil(+selectedPendingEmission.intensity) * +EMISSION_ASSET_AMOUNT;
        }
        let carbonProductHoldingSelector: ProductItemsAmount = {
            type: "ProductItemsAmount",
            productItemIds: itemIds,
            amount: totalAmount
        };
        let emissionProductHoldingSelector: ProductItemsAmount = {
            type: "ProductItemsAmount",
            productItemIds: [selectedPendingEmission!.id],
            amount: parseFloat(EMISSION_ASSET_AMOUNT)
        };
        const request: CreateProductItemRequest = {
            type: "CreateProductItemRequest",
            issuerId: inventoryAccount.id,
            productId: "TBD",
            canInflate: false,
            canFractionalize: false,
            isUnassigned: false,
            unitAmount: parseFloat(EMISSION_UNIT_AMOUNT),
            initialAmount: parseFloat(EMISSION_ASSET_AMOUNT),
            attributes: {},
            underlying: [
                carbonProductHoldingSelector,
                emissionProductHoldingSelector
            ]
        };

        throw Error("TBD")

        const response = submitAsync(request);
    };

    const { submitAsync, transactionState, resetTransactionState } = useSubmitTransaction();

    useEffect(() => {
        if (transactionState.status === "EXECUTED") {
            // show success dialog
            setDialogState(DialogState.SUCCESS);
            // stop button spinner
            setIsRequesting(false);

            // clear error state
            setErrorInfo({
                errorMessage: '',
                errorCode: '',
                warningMessage: ''
            });
            // redirect to decarbonised emission table
            navigate(`${APP_ROUTES.dashboard.commodities.path}/${EmissionTableType.DECARBONISED}`);
        } else if (transactionState.status === "FAILED") {
            setErrorInfo({
                errorMessage: 'An error occurred when retiring transactions.',
                errorCode: transactionState.transactionErrorCode,
                warningMessage: ''
            });

            // show error dialog
            setDialogState(DialogState.ERROR);
            // stop button spinner
            setIsRequesting(false);
        }
    }, [transactionState]);
	
    // fetch request for certificate URL
    const handleOpenCertificate = async (productItemId: string) => {
        const options = {
            includeBakedHoldings: true,
            includeRequest: true
        };

        try {

            let saveTransactionProofRequest: SaveTransactionProofRequest = {
                blockAndIndex: productItemId,
                includeBakedHoldings: options.includeBakedHoldings,
                includeRequest: options.includeRequest
            }

            const data = await cortenAuthApi?.metadata.saveTransactionProof(saveTransactionProofRequest);

            if (!data) {
                throw new Error('Failed to fetch certificate data');
            }

            // open certificate in new tab
            const newTab = window.open(data.viewUrl, '_blank');

            if (newTab) {
                // focus on the new tab
                newTab.focus();
            } else {
                // If new tab was blocked
                alert('Popup blocked. Please enable popups to view certificate.');
            }
        } catch (error) {
            console.error('error', error);
        }
    };

    return (
        <DecarboniseStateContext.Provider
            value={{
                fetchItemsForDecarbonisedAsset,
                decarbonisedItems,
                isDecarboniseFormOpen,
                handleDecarboniseFormOpen,
                dialogState,
                performDecarboniseEmissionAsset,
                isRequesting,
                txId,
                resetTransactionState,
                errorInfo,
                selectedPendingEmission
            }}
        >
            <DecarboniseDispatchContext.Provider
                value={{ setIsDecarboniseFormOpen, setDialogState, setTxId, handleOpenCertificate }}
            >
                {children}
            </DecarboniseDispatchContext.Provider>
        </DecarboniseStateContext.Provider>
    );
};

function useDecarboniseState() {
    const context = useContext(DecarboniseStateContext);
    if (!context) {
        throw new Error('no provider for useDecarboniseState');
    }
    return context;
}

function useDecarboniseDispatch() {
    const context = useContext(DecarboniseDispatchContext);
    if (!context) {
        throw new Error('no provider for useDecarboniseDispatch');
    }
    return context;
}

export { DecarboniseProvider, useDecarboniseState, useDecarboniseDispatch };
