import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faPalette } from '@fortawesome/free-solid-svg-icons/faPalette';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArrowDropDown } from '@mui/icons-material';
import { Box, Divider, IconButton, Menu, MenuItem, Tab, Tabs, Toolbar, Tooltip, Typography } from '@mui/material';
import { MouseEvent, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
    AppBar, 
    appThemes, 
    CustomTheme,
    navBarHeight, 
    useAppConfigState,
    useLayoutState
} from '@commodity-desk/common';
import { APP_ROUTES } from '../utility/AppRoutes';
import { homepage } from '../state/Variables';
import ANZ_LOGO from '../logos/anz-logo.png';
import TROVIO_LOGO from '../logos/trovio-logo.png';

interface NavItemInfo {
    label: string;
    pathName: string;
    hasSubMenu: boolean;
    subMenuPaths?: any;
    isEnabled?: boolean;
}

interface TabPathNameType {
    [key: string]: NavItemInfo;
}

const dashboardRoutes = APP_ROUTES.dashboard;

function getPathKeyFromIndex(path: string) {
    return path?.split('/')?.[2];
}

const Navigation = ({
    theme,
    setTheme,
    themeAnchorEl,
    setThemeAnchorEl
}: {
    theme: CustomTheme;
    setTheme: any;
    themeAnchorEl: any;
    setThemeAnchorEl: any;
}) => {
    const layoutState = useLayoutState();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [tabValue, setTabValue] = useState<string>(
        getPathKeyFromIndex(dashboardRoutes.inventoryManagement.path)
    );
    const appConfigState = useAppConfigState();

    const location = useLocation();
    const pathname = location.pathname;

    const TabPathName: TabPathNameType = {
        inventoryManagement: {
            label: dashboardRoutes.inventoryManagement.label,
            pathName: dashboardRoutes.inventoryManagement.path,
            hasSubMenu: false,
            isEnabled: true
        },
        clientManagement: {
            label: dashboardRoutes.clientManagement.label,
            pathName: dashboardRoutes.clientManagement.path,
            hasSubMenu: false,
            isEnabled: true
        },
        newTrade: {
            label: 'New Trade',
            pathName: `${homepage}/new-trade`,
            hasSubMenu: true,
            isEnabled: true,
            subMenuPaths: {
                physicalTrade: {
                    pathname: dashboardRoutes.physicalTrade.path,
                    label: 'Physical Trade',
                    isEnabled: true
                },
                forwardTrade: {
                    pathname: dashboardRoutes.forwardTrade.path,
                    label: 'Forward Trade',
                    isEnabled: true
                },
                futureTrade: {
                    pathname: `${homepage}/future-trade`,
                    label: 'Future Trade',
                    isEnabled: false
                },
                projectOfftake: {
                    pathname: `${homepage}/project-offtake`,
                    label: 'Project Offtake',
                    isEnabled: false
                },
                vanillaOption: {
                    pathname: `${homepage}/vanilla-option`,
                    label: 'Vanilla Option',
                    isEnabled: false
                },
                stapleAssets: {
                    pathname: `${homepage}/staple-assets`,
                    label: 'Staple Assets',
                    isEnabled: false
                },
                retirement: {
                    pathname: dashboardRoutes.retirementTrade.path,
                    label: 'Retirement',
                    isEnabled: true
                }
            }
        },
        tradeBlotter: {
            label: dashboardRoutes.tradeBlotter.label,
            pathName: dashboardRoutes.tradeBlotter.path,
            hasSubMenu: false,
            isEnabled: true
        },
        risk: {
            label: dashboardRoutes.risk.label,
            pathName: dashboardRoutes.risk.path,
            hasSubMenu: false,
            isEnabled: true
        },
        curve: {
            label: dashboardRoutes.curve.label,
            pathName: dashboardRoutes.curve.path,
            hasSubMenu: false,
            isEnabled: true
        },
        inventoryScore: {
            label: dashboardRoutes.inventoryScore.label,
            pathName: dashboardRoutes.inventoryScore.path,
            hasSubMenu: false,
            isEnabled: true
        },
        emissions: {
            label: dashboardRoutes.commodities.label,
            pathName: dashboardRoutes.commodities.path,
            hasSubMenu: false,
            isEnabled: appConfigState.commoditiesFeatureEnabled()
        },
        aggregator: {
            label: dashboardRoutes.aggregator.label,
            pathName: dashboardRoutes.aggregator.path,
            hasSubMenu: false,
            isEnabled: appConfigState.enableAggregator()
        }
    };

    // nav item text highlighting
    const navTextColor = {
        color: theme.customProps.navBarText
    };

    // To handle New Trade dropdown menu active tab selection, we need this array
    // Return array which contains pathnames of newTrade dropdown menu from TabPathName(Menu lists)
    const newTradePaths = Object.keys(TabPathName.newTrade.subMenuPaths).map((key) =>
        getPathKeyFromIndex(TabPathName.newTrade.subMenuPaths[key].pathname)
    );

    // Side effects to set active Tab value based on current pathname
    useEffect(() => {
        const pathKey = getPathKeyFromIndex(pathname);
        if (pathKey) {
            // Check if the current pathname is from New Trade dropdown menu
            // If yes, set  New Trade tab as Active tab
            if (newTradePaths?.includes(pathKey)) {
                setTabValue(getPathKeyFromIndex(TabPathName.newTrade.pathName));
                setAnchorEl(null);
                return;
            }
            // Else, set Active tab based on current pathname
            setTabValue(pathKey);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const handleMenuOpen = (event: MouseEvent<any>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    function selectTheme(t: CustomTheme) {
        setTheme(t);
        setThemeAnchorEl(undefined);
    }

    return (
        <AppBar position="fixed" open={layoutState.open}>
            <Toolbar variant="dense" sx={{ height: navBarHeight }}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={layoutState.handleDrawerOpen}
                    sx={{ mr: 2, ...(layoutState.open && { display: 'none' }) }}
                >
                    <FontAwesomeIcon icon={faBars} />
                </IconButton>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%'
                    }}
                >
                    <Box display="flex" alignItems="center" justifyContent="center" p={2}>
                        {
                            theme.customProps.name === 'anz' 
                            ?
                            <img src={ANZ_LOGO} alt="Logo" style={{ width: '90px', height: 'auto' }} />
                            :
                            <img src={TROVIO_LOGO} alt="Logo" style={{ width: '90px', height: 'auto' }} />
                        }
                    </Box>
                    <Divider orientation="vertical" sx={{ background: theme.customProps.navBarText, marginRight: '1rem', height: 20 }} />

                    <Typography component="div" sx={{ ...navTextColor, mr: 7, fontWeight: 'bold' }}>
                        Environmental Products Desk
                    </Typography>

                    {/* Tabs Menus */}
                    <Tabs value={tabValue}>
                        {Object.keys(TabPathName).map((key, index) => {
                            // Return null if the tab is disabled via config
                            if (!TabPathName[key]?.isEnabled) {
                                return null;
                            }

                            // Return Dropdown if the menu has submenu
                            if (TabPathName[key]?.hasSubMenu) {
                                return (
                                    <Tab
                                        key={index}
                                        label={
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    flexWrap: 'nowrap'
                                                }}
                                            >
                                                {TabPathName[key]?.label}
                                                {!!TabPathName[key]?.hasSubMenu && (
                                                    <ArrowDropDown />
                                                )}
                                            </Box>
                                        }
                                        sx={{
                                            ...navTextColor,
                                            transition: theme.customProps.navHoverTransition,
                                            '&:hover': {
                                                backgroundColor:
                                                    theme.customProps.navBarHoverBackgroundColor
                                            },
                                            "&.Mui-selected": {
                                                color: theme.customProps.navBarTextSelected,
                                                backgroundColor: theme.customProps.navBarHoverBackgroundColor
                                            },
                                            "& .MuiTabs-indicator": {
                                                backgroundColor: theme.customProps.navBarTextSelected
                                            }    
                                        }}
                                        onClick={handleMenuOpen}
                                        value={getPathKeyFromIndex(TabPathName[key]?.pathName)}
                                    />
                                );
                            }

                            // Return Rest of the menu lists
                            return (
                                <Tab
                                    key={index}
                                    label={TabPathName[key]?.label}
                                    component={Link}
                                    to={TabPathName[key]?.pathName}
                                    sx={{
                                        "& .MuiTabs-indicator": {
                                            backgroundColor: theme.customProps.navBarTextSelected
                                        },
                                        ...navTextColor,
                                        transition: theme.customProps.navHoverTransition,
                                        '&:hover': {
                                            backgroundColor:
                                                theme.customProps.navBarHoverBackgroundColor
                                        },
                                        "&.Mui-selected": {
                                            color: theme.customProps.navBarTextSelected,
                                            backgroundColor: theme.customProps.navBarHoverBackgroundColor
                                        },
                                    }}
                                    value={getPathKeyFromIndex(TabPathName[key]?.pathName)}
                                />
                            );
                        })}
                    </Tabs>

                    {/* Theme Mode Menu */}
                    <Box sx={{ marginLeft: 'auto' }}>
                        <IconButton
                            onClick={(e) => setThemeAnchorEl(e.currentTarget)}
                            sx={{color: theme.customProps.navBarText}}
                        >
                            <Tooltip title="Select theme">
                                <FontAwesomeIcon icon={faPalette} />
                            </Tooltip>
                        </IconButton>
                        <Menu
                            open={Boolean(themeAnchorEl)}
                            anchorEl={themeAnchorEl}
                            onClose={() => setThemeAnchorEl()}
                        >
                            {appConfigState.getThemes().map((item) => (
                                <MenuItem
                                    key={item.name}
                                    onClick={() =>
                                        selectTheme(appThemes[item.name as keyof typeof appThemes])
                                    }
                                >
                                    {item.displayName}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    {/* Dropdown menu for New Trade */}
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => handleMenuClose()}
                    >
                        {Object.keys(TabPathName.newTrade?.subMenuPaths)?.map((key: string) => (
                            <MenuItem
                                key={key}
                                component={Link}
                                to={TabPathName.newTrade?.subMenuPaths[key]?.pathname}
                                disabled={!TabPathName.newTrade?.subMenuPaths[key]?.isEnabled}
                            >
                                {TabPathName.newTrade?.subMenuPaths[key]?.label}
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Navigation;
