import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import {
    submitUnsignedTransaction,
    useAuth,
    useCortenApiState
} from '@trovio-tech/trovio-core-api-jsx';
import {
    CreateProductItemRequest,
    DocumentData,
    HoldingData,
    ListHoldingsRequest,
    ListHoldingsStateEnum,
    PagedListResponseHoldingData
} from '@trovio-tech/trovio-core-api-js';
import {
    CommodityFormSchema,
    CommodityFormFieldDefinition,
    FieldDataType,
    getCommodityAmountFieldDefinitionKey,
    getProductUnitAmount,
    inventoryAccount,
    useAppConfigState,
    useProductDataState
} from '@commodity-desk/common';
import { CommodityConfig } from './CommodityConfig';
import { stringToBoolean } from '../../../utility/BooleanUtils';
import { PaginationState } from '@tanstack/react-table';
import { usePagination } from '@trovio-ui-libs/ui';

interface EmissionDisplay {
    id: string;
    name: string;
    intensity: string;
    document?: any;
}

/**
 * The various states that dialogs in the UI can be part of
 */
enum DialogState {

    /**
     * Dialog will render a form for the user
     */
    FORM,

    /**
     * Dialog will render all the fields that have been previously
     * entered by the user for review
     */
    REVIEW,

    /**
     * Dialog will render a success feedback to the user
     */
    SUCCESS,

    /**
     * Dialog will render a error feedback to the user
     */
    ERROR,

    /**
     * Special state to denote that no dialog is being rendered to the user
     */
    HIDDEN
}

enum EmissionTableType {
    PENDING = 'pending',
    DECARBONISED = 'offset'
}

const TabPaths = [
    {
        label: EmissionTableType.PENDING,
        pathName: EmissionTableType.PENDING
    },
    {
        label: EmissionTableType.DECARBONISED,
        pathName: EmissionTableType.DECARBONISED
    }
];

interface CommoditiesStateType {
    handleCommoditiesFormOpen: () => void;
    commodityItems: HoldingData[];
    submitCommoditiesForm: (data: any) => void;
    getNextPageToken: (pageIndex: number) => string | undefined;
    uploadFile: (event: React.ChangeEvent<HTMLInputElement>) => Promise<DocumentData>;
    loadingTable: boolean;
    totalCount: number | undefined;
    dialogState: DialogState;
    performIssueCommodity: (formData: CommodityFormSchema, attrs: CommodityFormFieldDefinition[]) => void;
    requesting: boolean;
    commodityConfig: CommodityConfig;
    pagination: PaginationState;
    onPaginationChange: Dispatch<SetStateAction<PaginationState>>;
}

interface CommoditiesDispatchType {
    setDialogState: Dispatch<SetStateAction<DialogState>>;
    setLoadingTable: Dispatch<SetStateAction<boolean>>;
    resetTable: () => void;
}

const CommoditiesStateContext = createContext<CommoditiesStateType | null>(null);
const CommoditiesDispatchContext = createContext<CommoditiesDispatchType | null>(null);

const CommoditiesProvider = ({ children }: { children?: ReactNode }) => {
    const [commodityItems, setCommodityItems] = useState<HoldingData[]>([]);
    const [totalCount, setTotalCount] = useState<number | undefined>(undefined);
    const [loadingTable, setLoadingTable] = useState<boolean>(false);
    const [dialogState, setDialogState] = useState<DialogState>(DialogState.HIDDEN);
    const [requesting, setRequesting] = useState<boolean>(false);
    const mapPageToNextCursor = useRef<{ [page: number]: string | undefined }>({});
    const [currentPageSize, setCurrentPageSize] = useState<number>(0);
    const { pagination, onPaginationChange } = usePagination();

    const appConfigState = useAppConfigState();
    const user = useAuth();
    const { cortenAuthApi } = useCortenApiState();
    const commodityConfig = new CommodityConfig(appConfigState);
    const { productsData } = useProductDataState();

    // open form
    const handleCommoditiesFormOpen = () => {
        setDialogState(DialogState.FORM);
    };

    const getNextPageToken = (pageIndex: number): string | undefined => {
        return mapPageToNextCursor.current[pageIndex];
    }

    // request for UNSPENT emission product items
    const fetchUnspentHoldings = async ({
        pageIndex,
        pageSize
    }: {
        pageIndex: number;
        pageSize?: number
    }): Promise<void> => {
        setLoadingTable(true);
        let pageIndexToUse = pageIndex - 1;

        if (pageSize && currentPageSize !== pageSize) {
            setCurrentPageSize(pageSize);
            // if the pageSize changes then we start from the first page again.
            pageIndexToUse = 0;
        }

        const listHoldingsRequest: ListHoldingsRequest = {
            productId: commodityConfig.getCommodityProductIds(),
            state: [ListHoldingsStateEnum.Unspent],
            includeProductItemData: true,
            pageFrom: getNextPageToken(pageIndexToUse),
            pageLimit: pageSize,
            pageWithCount: true
        }

        const response: PagedListResponseHoldingData = await cortenAuthApi?.account.listHoldings(listHoldingsRequest);

        setCommodityItems(response.list);
        setTotalCount(response.count);
        mapPageToNextCursor.current[pageIndex] = response.nextPage;
        setLoadingTable(false);
    };

    const resetTable = () => {
        onPaginationChange({ pageIndex: 0, pageSize: pagination.pageSize });
    }

    // upload document to corten
    const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>): Promise<DocumentData> => {
        // upload file here
        const file = event.target.files?.[0];

        if (file) {
            const request = {
                issuerId: inventoryAccount.id,
                file: file
            }
            const response: DocumentData = await cortenAuthApi.metadata.uploadAttributeDocument(request);

            if (!response) {
                throw new Error('Failed to upload the document');
            }

            return response;
        } else {
            throw new Error('Failed to attach the document');
        }
    };

    /**
     * set review dialog
     *
     * @param data value of each input coming from react hook form controller
     */
    const submitCommoditiesForm = async (data: any) => {
        // update dialog view
        setDialogState(DialogState.REVIEW);
    };

    // POST request to create product item
    const performIssueCommodity = async (formData: CommodityFormSchema, attrs: CommodityFormFieldDefinition[]) => {
        setRequesting(true);

        const commodityType = commodityConfig.getCommodityTypeForProductId(formData.productId)!;
        const initialAmount = formData[getCommodityAmountFieldDefinitionKey(commodityType)];
        const unitAmount = getProductUnitAmount(productsData.get(formData.productId)!);

        const request: CreateProductItemRequest = {
            type: "CreateProductItemRequest",
            issuerId: inventoryAccount.id,
            productId: formData.productId,
            canInflate: false,
            canFractionalize: false,
            unitAmount: unitAmount,
            initialAmount: parseFloat(initialAmount),
            attributes: {},
            isUnassigned: false
        };

        attrs
            .filter(a => a.attribute)
            .forEach((attr) => {
                if (formData[attr.key]) {
                    switch (attr.dataType) {
                        case FieldDataType.Date:
                        case FieldDataType.DateYearMonth:
                            request.attributes![attr.attribute?.key!] = formData[attr.key].startOf('day').toDate().getTime();
                            break;
                        case FieldDataType.Boolean:
                            request.attributes![attr.attribute?.key!] = stringToBoolean(formData[attr.key]) as any;
                            break;
                        case FieldDataType.Timestamp:
                            throw Error("Unhandled Field Data Type")
                        default:
                            request.attributes![attr.attribute?.key!] = formData[attr.key];
                    }
                }
            })

        try {
            const response = await submitUnsignedTransaction(request, user, cortenAuthApi);
            if (!response) {
                throw new Error('Failed to perform request');
            }

            // show success dialog
            setDialogState(DialogState.SUCCESS);
        } catch (error) {
            console.error(error);

            // show error dialog
            setDialogState(DialogState.ERROR);
        }

        setRequesting(false);
    };

    useEffect(() => {
        fetchUnspentHoldings({
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize
        }).then();
    }, [pagination]);

    return (
        <CommoditiesStateContext.Provider
            value={{
                handleCommoditiesFormOpen,
                commodityItems,
                submitCommoditiesForm,
                getNextPageToken,
                uploadFile,
                loadingTable,
                totalCount,
                dialogState,
                performIssueCommodity,
                requesting,
                commodityConfig,
                pagination,
                onPaginationChange
            }}
        >
            <CommoditiesDispatchContext.Provider
                value={{
                    setDialogState,
                    setLoadingTable,
                    resetTable
                }}
            >
                {children}
            </CommoditiesDispatchContext.Provider>
        </CommoditiesStateContext.Provider>
    );
};

function useCommoditiesState() {
    const context = useContext(CommoditiesStateContext);
    if (!context) {
        throw new Error('no provider for useCommoditiesState');
    }
    return context;
}

function useCommoditiesDispatch() {
    const context = useContext(CommoditiesDispatchContext);
    if (!context) {
        throw new Error('no provider for useCommoditiesDispatch');
    }
    return context;
}

export {
    CommoditiesProvider,
    useCommoditiesState,
    useCommoditiesDispatch,
    DialogState,
    EmissionTableType,
    type EmissionDisplay,
    TabPaths
};
