var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { inventoryAccount } from '../state/AppConfig';
import dayjs from 'dayjs';
;
;
;
/**
 * Fetch all product balances
 *
 * @param criteria       The set of criteria to apply when fetching balances
 * @param api            TrovioCoreApi to use for making call to corten
 * @param allProductIds  An optional fallback list of all product IDs, must be supplied if productID is not included in 'criteria' (and is only used in that scenario)
 * @param signal         An optional AbortSignal to allow this request to be aborted by the caller
 * @returns list of all balances based on the provided criteria
 */
var fetchBalances = function (_a) {
    var criteria = _a.criteria, api = _a.api, _b = _a.allProductIds, allProductIds = _b === void 0 ? undefined : _b, _c = _a.signal, signal = _c === void 0 ? undefined : _c;
    return __awaiter(void 0, void 0, void 0, function () {
        var productId, listBalancesRequest, nextPage, combinedBalances, response;
        var _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    productId = (_d = criteria.productId) !== null && _d !== void 0 ? _d : allProductIds;
                    listBalancesRequest = {
                        productId: productId
                    };
                    if (criteria.sumProductItems) {
                        listBalancesRequest.sumProductItems = criteria.sumProductItems;
                    }
                    if (criteria.accountId) {
                        listBalancesRequest.accountId = [criteria.accountId];
                    }
                    if (criteria.productItemId) {
                        listBalancesRequest.productItemId = [criteria.productItemId];
                    }
                    (_e = criteria.attributes) === null || _e === void 0 ? void 0 : _e.filter(function (attr) { return attr.value !== ""; }).map(function (attr) { return listBalancesRequest.attributes[attr.code] = attr.value; });
                    if (criteria.includeProductItemData) {
                        listBalancesRequest.includeProductItemData = criteria.includeProductItemData;
                    }
                    nextPage = undefined;
                    combinedBalances = [];
                    _f.label = 1;
                case 1: return [4 /*yield*/, api.account.listCurrentBalances(listBalancesRequest, __assign({}, (signal && { signal: signal })))];
                case 2:
                    response = _f.sent();
                    combinedBalances.push.apply(combinedBalances, response.list);
                    nextPage = response.nextPage;
                    _f.label = 3;
                case 3:
                    if (nextPage) return [3 /*break*/, 1];
                    _f.label = 4;
                case 4: return [2 /*return*/, combinedBalances];
            }
        });
    });
};
/**
 * Fetch holding details by holding ID
 * @param id
 * @returns details for the given holding
 */
var fetchHolding = function (id, api) { return __awaiter(void 0, void 0, void 0, function () {
    var getHoldingInfoRequest, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                getHoldingInfoRequest = {
                    holdingId: id
                };
                return [4 /*yield*/, api.account.getHoldingInfo(getHoldingInfoRequest)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); };
var fetchProducts = function (api, issuerId, configuredProducts) { return __awaiter(void 0, void 0, void 0, function () {
    var nextPage, combinedData, configuredProductIds_1, listProductsRequest, response, data, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 6]);
                nextPage = undefined;
                combinedData = [];
                configuredProductIds_1 = configuredProducts === null || configuredProducts === void 0 ? void 0 : configuredProducts.map(function (product) { return product.id; });
                _a.label = 1;
            case 1:
                listProductsRequest = {
                    issuerId: issuerId ? [issuerId] : undefined
                };
                if (configuredProductIds_1) {
                    listProductsRequest.productId = configuredProductIds_1;
                }
                if (nextPage) {
                    listProductsRequest.pageFrom = nextPage;
                }
                response = api.productInventory.listProducts(listProductsRequest);
                return [4 /*yield*/, response];
            case 2:
                data = _a.sent();
                combinedData.push.apply(combinedData, data.list);
                nextPage = data.nextPage;
                _a.label = 3;
            case 3:
                if (nextPage) return [3 /*break*/, 1];
                _a.label = 4;
            case 4:
                if (configuredProductIds_1) {
                    // sort results by configured order
                    combinedData.sort(function (product1, product2) { return configuredProductIds_1.indexOf(product1.productId) - configuredProductIds_1.indexOf(product2.productId); });
                }
                return [2 /*return*/, combinedData];
            case 5:
                error_1 = _a.sent();
                console.error('error', error_1);
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}); };
/**
 * Fetch product attributes
 * @param productId
 * @returns all product attributes for a given product ID
 */
var fetchProductAttributesWithId = function (productId, api, signal) {
    if (signal === void 0) { signal = undefined; }
    return __awaiter(void 0, void 0, void 0, function () {
        var getProductRequest, productAttributeData, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    getProductRequest = {
                        productId: productId
                    };
                    return [4 /*yield*/, api.productInventory.getProduct(getProductRequest, __assign({}, (signal && { signal: signal })))];
                case 1:
                    productAttributeData = _a.sent();
                    return [2 /*return*/, productAttributeData];
                case 2:
                    error_2 = _a.sent();
                    console.error('error', error_2);
                    throw error_2;
                case 3: return [2 /*return*/];
            }
        });
    });
};
/**
 * Fetch product Items
 * @param productId - The ID of the product to fetch items for
 * @param attributeFilters - An optional array of filters on attributes to use when looking up product items, to only
 *                           return items with attributes that match the criteria.
 * @param isUnassigned - Whether to return only assigned items (false), unassigned items (true) or all items (undefined). Returns assigned items only by default.
 * @returns all batches of product items for a given product ID, matching attribute filters if specified
 */
var fetchProductItemsWithId = function (api, productId, attributeFilters, isUnassigned) {
    if (attributeFilters === void 0) { attributeFilters = undefined; }
    if (isUnassigned === void 0) { isUnassigned = false; }
    return __awaiter(void 0, void 0, void 0, function () {
        var nextPageProductItems, combinedProductItems, _loop_1, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    nextPageProductItems = undefined;
                    combinedProductItems = [];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    _loop_1 = function () {
                        var listProductItemsRequest, response, productItems;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    listProductItemsRequest = {
                                        productId: productId,
                                        isUnassigned: isUnassigned !== undefined ? isUnassigned : false,
                                        attributes: {}
                                    };
                                    attributeFilters === null || attributeFilters === void 0 ? void 0 : attributeFilters.filter(function (attr) { return attr.value !== ""; }).map(function (attr) { return listProductItemsRequest.attributes[attr.code] = attr.value; });
                                    if (nextPageProductItems) {
                                        listProductItemsRequest.pageFrom = nextPageProductItems;
                                    }
                                    return [4 /*yield*/, api.productInventory.listProductItems(listProductItemsRequest)];
                                case 1:
                                    response = _b.sent();
                                    productItems = response;
                                    // combine all batches
                                    combinedProductItems.push.apply(combinedProductItems, productItems.list);
                                    // set next batch page
                                    nextPageProductItems = productItems.nextPage;
                                    return [2 /*return*/];
                            }
                        });
                    };
                    _a.label = 2;
                case 2: return [5 /*yield**/, _loop_1()];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    if (nextPageProductItems) return [3 /*break*/, 2];
                    _a.label = 5;
                case 5: return [2 /*return*/, combinedProductItems];
                case 6:
                    error_3 = _a.sent();
                    console.error('error', error_3);
                    throw error_3;
                case 7: return [2 /*return*/];
            }
        });
    });
};
/**
 * Fetch account balances for a given criteria
 * @param criteria  Criteria including product, account ID and any filters
 * @returns
 */
var fetchAccountBalances = function (_a) {
    var api = _a.api, criteria = _a.criteria, appConfigState = _a.appConfigState;
    return __awaiter(void 0, void 0, void 0, function () {
        var listCurrentBalancesRequest, nextPage, combinedBalances, balances, error_4;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    listCurrentBalancesRequest = {
                        productId: (_b = criteria.productId) !== null && _b !== void 0 ? _b : appConfigState.getProducts().map(function (data) { return data.id; }),
                        attributes: {},
                        pageLimit: 200
                    };
                    if (criteria.sumProductItems) {
                        listCurrentBalancesRequest.sumProductItems = criteria.sumProductItems;
                    }
                    if (criteria.accountId) {
                        listCurrentBalancesRequest.accountId = [criteria.accountId];
                    }
                    if (criteria.productItemId) {
                        listCurrentBalancesRequest.productItemId = [criteria.productItemId];
                    }
                    (_c = criteria.attributes) === null || _c === void 0 ? void 0 : _c.filter(function (attr) { return attr.value !== ""; }).map(function (attr) {
                        listCurrentBalancesRequest.attributes[attr.code] = attr.value;
                    });
                    if (criteria.includeProductItemData) {
                        listCurrentBalancesRequest.includeProductItemData = criteria.includeProductItemData;
                    }
                    nextPage = undefined;
                    combinedBalances = [];
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 6, , 7]);
                    _d.label = 2;
                case 2:
                    if (nextPage) {
                        listCurrentBalancesRequest.pageFrom = nextPage;
                    }
                    return [4 /*yield*/, api.account.listCurrentBalances(listCurrentBalancesRequest)];
                case 3:
                    balances = _d.sent();
                    if (!balances) {
                        throw new Error('Failed to fetch account balances');
                    }
                    // combine all batches
                    combinedBalances.push.apply(combinedBalances, balances.list);
                    // set next batch page
                    nextPage = balances.nextPage;
                    _d.label = 4;
                case 4:
                    if (nextPage) return [3 /*break*/, 2];
                    _d.label = 5;
                case 5: return [2 /*return*/, combinedBalances];
                case 6:
                    error_4 = _d.sent();
                    console.error('error', error_4);
                    throw error_4;
                case 7: return [2 /*return*/];
            }
        });
    });
};
/**
 *
 * Fetch product data by attributes using 'group by' api
 *
 * @param criteria see {@link ProductCriteria}
 * @return a list of attribute groups that match provided criteria
 */
var fetchByCriteria = function (criteria, api, signal) {
    if (signal === void 0) { signal = undefined; }
    return __awaiter(void 0, void 0, void 0, function () {
        var nextPage, combinedData, _loop_2, error_5;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 5, , 6]);
                    nextPage = undefined;
                    combinedData = [];
                    _loop_2 = function () {
                        var listBalanceByAttributesRequest, response, data;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    listBalanceByAttributesRequest = {
                                        productIds: criteria.productIds,
                                        axes: criteria.axes,
                                        attributes: {},
                                        isUnassigned: criteria.isUnassigned == true
                                    };
                                    if (criteria.accountId) {
                                        listBalanceByAttributesRequest.accountId = criteria.accountId;
                                    }
                                    (_a = criteria.attributes) === null || _a === void 0 ? void 0 : _a.map(function (attr) { return listBalanceByAttributesRequest.attributes[attr.code] = attr.value; });
                                    if (criteria.includeBalances) {
                                        listBalanceByAttributesRequest.includeBalances = criteria.includeBalances;
                                    }
                                    if (nextPage) {
                                        listBalanceByAttributesRequest.pageFrom = nextPage;
                                    }
                                    response = api.productInventory.listBalanceByAttributes(listBalanceByAttributesRequest, __assign({}, (signal && { signal: signal })));
                                    return [4 /*yield*/, response];
                                case 1:
                                    data = _c.sent();
                                    combinedData.push.apply(combinedData, data.list);
                                    nextPage = data.nextPage;
                                    return [2 /*return*/];
                            }
                        });
                    };
                    _b.label = 1;
                case 1: return [5 /*yield**/, _loop_2()];
                case 2:
                    _b.sent();
                    _b.label = 3;
                case 3:
                    if (nextPage) return [3 /*break*/, 1];
                    _b.label = 4;
                case 4: return [2 /*return*/, { list: combinedData }];
                case 5:
                    error_5 = _b.sent();
                    console.error('error', error_5);
                    return [2 /*return*/, { list: [] }];
                case 6: return [2 /*return*/];
            }
        });
    });
};
/**
 * Fetch product item details by product item ID
 * @param id
 * @returns details for the given product item
 */
/**
 * Fetch transactions for a specific product item ID
 *
 * @param productItemId The product item ID to fetch transactions for
 */
var fetchTransactionsByProductItem = function (productItemId, api) { return __awaiter(void 0, void 0, void 0, function () {
    var listTransactionsRequest;
    return __generator(this, function (_a) {
        listTransactionsRequest = {
            issuerId: inventoryAccount.id,
            productItemId: productItemId
        };
        try {
            return [2 /*return*/, api.account.listTransactions(listTransactionsRequest)];
        }
        catch (error) {
            console.error(error);
            throw error;
        }
        return [2 /*return*/];
    });
}); };
/**
 * Fetch filtered product items, grouped by project and vintage and enriched with curve price data
 *
 * @param request The product item filters to apply, see {@link ListOrderedProjectVintagesRequest}
 * @param api The api object to make calls with
 */
var fetchOrderedProductItems = function (_a) {
    var request = _a.request, api = _a.api;
    return __awaiter(void 0, void 0, void 0, function () {
        var error_6;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.orderedProjectVintages.listOrderedProjectVintages(request)];
                case 1: return [2 /*return*/, _b.sent()];
                case 2:
                    error_6 = _b.sent();
                    console.error(error_6);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
};
/**
 * Fetch vintage-project prices
 *
 * @param productId The ID of the product
 * @param endOfDay Optional, "ISO 8601"-formatted EOD date
 * @param api The api object to make calls with
 */
var fetchCurvePrices = function (_a) {
    var productId = _a.productId, endOfDay = _a.endOfDay, api = _a.api;
    return __awaiter(void 0, void 0, void 0, function () {
        var request, error_7;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    request = {
                        productId: productId,
                        endOfDay: endOfDay
                    };
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.curve.getPrices(request)];
                case 2: return [2 /*return*/, _b.sent()];
                case 3:
                    error_7 = _b.sent();
                    console.error(error_7);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
};
/**
 * Fetch project prices
 *
 * @param productId The ID of the product
 * @param endOfDay Optional, "ISO 8601"-formatted EOD date
 * @param api The api object to make calls with
 */
var fetchProjectPrices = function (_a) {
    var productId = _a.productId, endOfDay = _a.endOfDay, api = _a.api;
    return __awaiter(void 0, void 0, void 0, function () {
        var request, error_8;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    request = {
                        productId: productId,
                        endOfDay: endOfDay
                    };
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.curve.getProjectPrices(request)];
                case 2: return [2 /*return*/, _b.sent()];
                case 3:
                    error_8 = _b.sent();
                    console.error(error_8);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
};
/**
 * Fetch all dates for which an EOD curve is configured
 *
 * @param productId The ID of the product
 * @param api The api object to make calls with
 */
var fetchCurveEndOfDayDates = function (_a) {
    var productId = _a.productId, api = _a.api;
    return __awaiter(void 0, void 0, void 0, function () {
        var request, error_9;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    request = {
                        productId: productId
                    };
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.curve.listEndOfDayDates(request)];
                case 2: 
                // The generated TS client doesn't actually convert the response to an array of
                // Date objects and instead returns it as string. So we need to do this
                // "as unknown as string[]" cast here to an array of string to get around this issue
                return [2 /*return*/, (_b.sent())];
                case 3:
                    error_9 = _b.sent();
                    console.error(error_9);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
};
/**
 * Fetch the complete curve marking
 *
 * @param productId The ID of the product
 * @param user The authenticated user
 * @param api The api object to make calls with
 */
var fetchCurveMarking = function (_a) {
    var productId = _a.productId, endOfDay = _a.endOfDay, api = _a.api;
    return __awaiter(void 0, void 0, void 0, function () {
        var request, error_10;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    request = {
                        productId: productId,
                        endOfDay: endOfDay
                    };
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.curve.getCurve(request)];
                case 2: return [2 /*return*/, _b.sent()];
                case 3:
                    error_10 = _b.sent();
                    console.error(error_10);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
};
/**
 * Save the complete curve marking
 *
 * @param curveMarking The complete curve marking
 * @param endOfDay Save as EOD curve for today's date
 * @param api The api object to make calls with
 */
var saveCurveMarking = function (_a) {
    var curveMarking = _a.curveMarking, _b = _a.endOfDay, endOfDay = _b === void 0 ? false : _b, api = _a.api;
    return __awaiter(void 0, void 0, void 0, function () {
        var request;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    request = {
                        utcOffset: dayjs().utcOffset(),
                        curveMarking: curveMarking,
                        endOfDay: endOfDay
                    };
                    return [4 /*yield*/, api.curve.saveCurve(request)];
                case 1: return [2 /*return*/, _c.sent()];
            }
        });
    });
};
/**
 * Fetch the inventory score marking
 *
 * @param productId The ID of the product
 * @param endOfDay  Fetch price at a given date. Leave undefined to get current price
 * @param abortSignal Used for aborting any ongoing outdated requests
 * @param api The api object to make calls with
 */
var fetchInventoryScoreMarking = function (_a) {
    var productId = _a.productId, endOfDay = _a.endOfDay, abortSignal = _a.abortSignal, api = _a.api;
    return __awaiter(void 0, void 0, void 0, function () {
        var request, error_11;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    request = {
                        productId: productId,
                        endOfDay: endOfDay
                    };
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.optimiserScore.getOptimiserScores(request, __assign({}, (abortSignal && { signal: abortSignal })))];
                case 2: return [2 /*return*/, _b.sent()];
                case 3:
                    error_11 = _b.sent();
                    console.error(error_11);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
};
/**
 * Save the inventory score marking
 *
 * @param marking The inventory score marking
 * @param endOfDay Save as EOD snapshot for today's date
 * @param api The api object to make calls with
 */
var saveInventoryScoreMarking = function (_a) {
    var _b = _a.endOfDay, endOfDay = _b === void 0 ? false : _b, marking = _a.marking, api = _a.api;
    return __awaiter(void 0, void 0, void 0, function () {
        var request;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    request = {
                        endOfDay: endOfDay,
                        baseOptimiserScoreMarking: marking
                    };
                    return [4 /*yield*/, api.optimiserScore.saveOptimiserScores(request)];
                case 1: return [2 /*return*/, _c.sent()];
            }
        });
    });
};
/**
 * Fetch computed inventory scores
 *
 * @param productId The ID of the product
 * @param endOfDay  Fetch computed inventory score at a given date. Leave undefined to get current score
 * @param abortSignal Used for aborting any ongoing outdated requests
 * @param api The api object to make calls with
 */
var fetchComputedInventoryScores = function (_a) {
    var productId = _a.productId, endOfDay = _a.endOfDay, abortSignal = _a.abortSignal, api = _a.api;
    return __awaiter(void 0, void 0, void 0, function () {
        var request, error_12;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    request = {
                        productId: productId,
                        endOfDay: endOfDay
                    };
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.optimiserScore.getCalculatedScores(request, __assign({}, (abortSignal && { signal: abortSignal })))];
                case 2: return [2 /*return*/, _b.sent()];
                case 3:
                    error_12 = _b.sent();
                    console.error(error_12);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
};
/**
 * Tag projects with the final inventory score, to be used with selection algorithms
 *
 * @param productId The product to use to tag all included projects with inventory scores
 * @param api The api object to make calls with
 */
var tagProjectsWithInventoryScores = function (_a) {
    var productId = _a.productId, api = _a.api;
    return __awaiter(void 0, void 0, void 0, function () {
        var request;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    request = {
                        productId: productId
                    };
                    return [4 /*yield*/, api.optimiserScore.tagProjectsWithScore(request)];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    });
};
export { fetchProducts, fetchProductAttributesWithId, fetchProductItemsWithId, fetchBalances, fetchAccountBalances, fetchByCriteria, fetchHolding, fetchOrderedProductItems, fetchCurvePrices, fetchProjectPrices, fetchCurveEndOfDayDates, fetchCurveMarking, saveCurveMarking, fetchInventoryScoreMarking, saveInventoryScoreMarking, fetchComputedInventoryScores, fetchTransactionsByProductItem, tagProjectsWithInventoryScores };
