import {
    HoldingRetirement,
    useAppConfigState,
    useCommodityDeskApiContext,
    useLayoutState,
    useProductDataState
} from '@commodity-desk/common';
import { useAuth, useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';
import { Typography } from "@mui/material";

const RetirementTrade = () => {
    const { productsData } = useProductDataState();
    const {customTheme} = useLayoutState();
    const appConfigState = useAppConfigState();
    const user = useAuth();
    const { commodityDeskApi } = useCommodityDeskApiContext();
    const { cortenAuthApi } = useCortenApiState();
    return (
        <>
            <Typography align={'center'} variant='h2'>Retirement Ticket</Typography>
            <HoldingRetirement
                useDialog={false}
                accountId={undefined}
                holdings={[]}
                retirementDialogActive={true}
                onRetirementDialogClosed={undefined}
                preSelectedProductId={undefined}
                preSelectedProjectId={undefined}
                preSelectedProjectType={undefined}
                preSelectedVintage={undefined}
                productsData={productsData}
                customTheme={customTheme}
                appConfigState={appConfigState}
                user={user}
                commodityDeskApi={commodityDeskApi}
                cortenAuthApi={cortenAuthApi}
            />
        </>
    );
}

export  {RetirementTrade}