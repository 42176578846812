import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { 
    AppConfigProvider,
    CortenApiContextProvider,
    fetchAppConfig,
    transformConfig
} from '@commodity-desk/common';
import { homepage, LOGIN_CALLBACK_PATH, LOGOUT_CALLBACK_PATH, STORAGE_KEY_PREFIX } from './state/Variables';

(async function fetchConfigData() {
    try {
        let appConfigResponse = await fetchAppConfig("");
        if (appConfigResponse.ok) {
            let appConfigJson = await appConfigResponse.json();
            let authConfig = transformConfig(appConfigJson.GENERAL.AUTH_CONFIG, LOGIN_CALLBACK_PATH, LOGOUT_CALLBACK_PATH);

            ReactDOM.render(
                <React.StrictMode>
                    <AppConfigProvider appConfig={appConfigJson} portal={"ADMIN"}>
                        <CortenApiContextProvider
                            homepage={homepage}
                            authConfig={authConfig}
                            LOGIN_CALLBACK_PATH={LOGIN_CALLBACK_PATH}
                            LOGOUT_CALLBACK_PATH={LOGOUT_CALLBACK_PATH}
                            STORAGE_KEY_PREFIX={STORAGE_KEY_PREFIX}
                        >
                            <App/>
                        </CortenApiContextProvider>
                    </AppConfigProvider>
                </React.StrictMode>,
                document.getElementById('root')
            );

        } else {
            throw new Error(`${appConfigResponse.status}, ${appConfigResponse.statusText}`);
        }
    } catch (error) {
        console.error('resp not ok');
        console.error('something went wrong', error);
    }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
