import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { EmissionDisplay } from './CommoditiesState';
import { HoldingData, ListProductItemUnderlyingHoldingsRequest } from '@trovio-tech/trovio-core-api-js';
import {
    carbonProjectAttributes,
    emissionAttributes,
    inventoryAccount,
    SerialNumber,
    useAppConfigState,
} from '@commodity-desk/common';

import { useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';

interface UnderlyingHoldingDisplay {
    isEmission: boolean;
    holdingState: string;
    holdingId: string;
    accountId: string;
    amount: string;
    productCode: string;
    projectId?: string;
    projectName?: string;
    projectType?: string;
    projectCountry?: string;
    projectState?: string;
    projectUri?: string;
    projectVintage?: string;
    serialNumRange?: string;
    maxDecimalPos: number;
    minDecimalPos: number;
    emissionName?: string;
    emissionIntensity?: string;
    emissionDocument?: string;
}

const defaultUnderlyingDisplay = {
    isEmission: false,
    holdingState: '',
    holdingId: '',
    accountId: '',
    amount: '',
    productCode: '',
    maxDecimalPos: 0,
    minDecimalPos: 0
};

interface UnderlyingStateType {
    isUnderlyingDrawerOpen: boolean;
    selectedDecarbItem: EmissionDisplay | undefined;
    underlyingAssets: any[];
    loadingUnderlying: boolean;
    holdingData: UnderlyingHoldingDisplay;
    isUnderlyingHoldingDialogOpen: boolean;
    loadingHoldings: boolean;
    getProductDisplayCode: any;
}

interface UnderlyingDispatchType {
    setIsUnderlyingDrawerOpen: Dispatch<SetStateAction<boolean>>;
    handleUnderlyingDrawerOpen: (item: any) => void;
    handleOpenHoldingDetails: (item: any) => void;
    setIsUnderlyingHoldingDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const UnderlyingStateContext = createContext<UnderlyingStateType | null>(null);
const UnderlyingDispatchContext = createContext<UnderlyingDispatchType | null>(null);

const UnderlyingProvider = ({ children }: { children?: ReactNode }) => {
    const [isUnderlyingDrawerOpen, setIsUnderlyingDrawerOpen] = useState<boolean>(false);
    const [isUnderlyingHoldingDialogOpen, setIsUnderlyingHoldingDialogOpen] =
        useState<boolean>(false);

    const [selectedDecarbItem, setSelectedDecarbItem] = useState<EmissionDisplay | undefined>(
        undefined
    );
    const [underlyingAssets, setUnderlyingAssets] = useState<any>([]);
    const [loadingUnderlying, setLoadingUnderlying] = useState<boolean>(false);
    const [holdingData, setHoldingData] =
        useState<UnderlyingHoldingDisplay>(defaultUnderlyingDisplay);
    const [loadingHoldings, setLoadingHoldings] = useState<boolean>(false);

    const appConfigState = useAppConfigState();
    const { cortenAuthApi } = useCortenApiState();

    // open dialog for underlying assets
    const handleUnderlyingDrawerOpen = async (item: EmissionDisplay) => {
        // table loader
        setLoadingUnderlying(true);
        // open dialog
        setIsUnderlyingDrawerOpen(true);

        setSelectedDecarbItem(item);
        // perform fetch underlying
        await fetchUnderlying(item.id);
        // stop table loader
        setLoadingUnderlying(false);
    };

    // fetch decarbonsied assets underlying items
    const fetchUnderlying = async (productItemId: string) => {
        // clear next page
        let nextPageItems = undefined;
        // clear accumulated product Item batches
        let combinedUnderlyingItems = [];

        try {
            do {

                let listProductItemUnderlyingHoldings: ListProductItemUnderlyingHoldingsRequest = {
                    productItemId: productItemId
                }
                if (nextPageItems) {
                    listProductItemUnderlyingHoldings.pageFrom = nextPageItems;
                }

                const response = await cortenAuthApi?.productInventory.listProductItemUnderlyingHoldings(listProductItemUnderlyingHoldings);

                if (!response) {
                    throw new Error('Failed to fetch underlying items');
                }

                const productItems = await response;

                // combine all batches
                combinedUnderlyingItems.push(...productItems!.list);

                // set next batch page
                nextPageItems = productItems!.nextPage;
            } while (nextPageItems);

            setUnderlyingAssets(combinedUnderlyingItems);
        } catch (error) {
            console.error('error', error);
        }
    };

    const getProductDisplayCode = (holding: HoldingData): string => {
        if (appConfigState.getProduct(holding.productId!) !== undefined) {
            // carbon product
            return appConfigState.getProduct(holding.productId!)?.displayCode!;
        // } else if (holding.productId === appConfigState.getEmissionProductId('EMISSION_ASSET')) {
        //     // emission product
        //     return appConfigState.getEmissionProductDisplayCode()!
        } else {
            return ''
        }
    }

    // find serial number range
    const findSerialNumRange = (item: any) => {
        // check if we have serial number attribute
        const serialNumberStart =
            item.productItem.data.attributes[
                carbonProjectAttributes.projectSerialNumberStart.key
            ];

        if (serialNumberStart) {
            return SerialNumber.fromHolding(item, appConfigState)?.getRange(
                item.index,
                Number(item.amount)
            );
        }
    };

    // open holding details for underlying item
    const handleOpenHoldingDetails = async (item: any) => {
        // clear data
        setHoldingData(defaultUnderlyingDisplay);

        setLoadingHoldings(true);

        // open dialog
        setIsUnderlyingHoldingDialogOpen(true);

        let displaydata: UnderlyingHoldingDisplay = {
            isEmission: appConfigState.getProduct(item.productId) === undefined ? true : false, // check if it is an emission item or carbon product
            holdingState: item.state,
            holdingId: item.holdingId,
            accountId: inventoryAccount.display,
            amount: item.amount,
            productCode: getProductDisplayCode(item),
            projectId: item.productItem.data.attributes[carbonProjectAttributes.projectId.key],
            projectName: item.productItem.data.attributes[carbonProjectAttributes.projectName.key],
            projectType: item.productItem.data.attributes[carbonProjectAttributes.projectType.key],
            projectCountry: item.productItem.data.attributes[carbonProjectAttributes.country.key],
            projectState: item.productItem.data.attributes[carbonProjectAttributes.projectState.key],
            projectUri: item.productItem.data.attributes[carbonProjectAttributes.projectUri.key],
            projectVintage: item.productItem.data.attributes[carbonProjectAttributes.vintage.key],
            serialNumRange: findSerialNumRange(item),
            maxDecimalPos: item.product.data.maxDecimalPos,
            minDecimalPos: item.product.data.minDecimalPos,
            emissionName:
                item.productItem.data.attributes[
                    emissionAttributes.name.key
                ],
            emissionIntensity:
                item.productItem.data.attributes[
                    emissionAttributes.intensity.key
                ],
            emissionDocument:
                item.productItem.data.attributes[
                    emissionAttributes.document.key
                ]?.url
        };

        // set holding item to state
        setHoldingData(displaydata);

        // stop loader
        setLoadingHoldings(false);
    };

    return (
        <UnderlyingStateContext.Provider
            value={{
                isUnderlyingDrawerOpen,
                selectedDecarbItem,
                underlyingAssets,
                loadingUnderlying,
                holdingData,
                isUnderlyingHoldingDialogOpen,
                loadingHoldings,
                getProductDisplayCode
            }}
        >
            <UnderlyingDispatchContext.Provider
                value={{
                    setIsUnderlyingDrawerOpen,
                    handleUnderlyingDrawerOpen,
                    handleOpenHoldingDetails,
                    setIsUnderlyingHoldingDialogOpen
                }}
            >
                {children}
            </UnderlyingDispatchContext.Provider>
        </UnderlyingStateContext.Provider>
    );
};

function useUnderlyingState() {
    const context = useContext(UnderlyingStateContext);
    if (!context) {
        throw new Error('no provider for useUnderlyingState');
    }
    return context;
}

function useUnderlyingDispatch() {
    const context = useContext(UnderlyingDispatchContext);
    if (!context) {
        throw new Error('no provider for useUnderlyingDispatch');
    }
    return context;
}

export { UnderlyingProvider, useUnderlyingState, useUnderlyingDispatch };
