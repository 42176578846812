import { Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, Typography, } from '@mui/material';
import { ForwardTradeForm } from './ForwardTrade';
import { submitUnsignedTransaction, useAuth, useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';
import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { stringToBoolean } from '../../../utility/BooleanUtils';
import { 
    AlertDialogContent, 
    AlertType,
    DATE_DISPLAY_FORMAT,
    getFieldDefinitionMap,
    getFieldDefinitions,
    getProductAttributeUiElements,
    fetchProductItemsWithId,
    inventoryAccount,
    lgcAttributes,
    miqcAttributes,
    ProductType, 
    carbonProjectAttributes,
    renderDialogField,
    transactionAttributes,
    TransactionOverview,
    TransactionType, 
    useAppConfigState,
    useProductDataState,
    useSubmitTransaction,
} from '@commodity-desk/common';
import { CreateProductItemRequest, equalsExpr } from '@trovio-tech/trovio-core-api-js';

enum DialogState {
    REVIEW,
    SUCCESS,
    ERROR,
}

const ForwardTradeSubmitDialog = ({ open, onClose, form, projectName}: {
    open: boolean,
    onClose: (resetForm: boolean) => void,
    form: ForwardTradeForm,
    projectName: string | undefined,
}) => {
    const [dialogState, setDialogState] = useState<DialogState | null>(null);
    const [submitting, setSubmitting] = useState(false);
    const [transactionErrorCode, setTransactionErrorCode] = useState<string | null>(null);
    const [transactionId, setTransactionId] = useState<string | null>(null);

    const productItemData = useRef<Promise<any[]>>();
    const appConfigState = useAppConfigState();
    const { productsData } = useProductDataState();
    const { cortenAuthApi } = useCortenApiState();

    const fieldDefinitions = getFieldDefinitions({appConfigState: appConfigState, productsData: productsData});
    const fieldDefinitionMap = getFieldDefinitionMap({appConfigState: appConfigState, productsData: productsData});

    const { submitAsync, transactionState, resetTransactionState } = useSubmitTransaction();

    useEffect(() => {
        if (transactionState.status === "EXECUTED") {
            setDialogState(DialogState.SUCCESS)
        } else if (transactionState.status === "FAILED") {
            onTransactionError(transactionState.transactionErrorCode)
        }
    }, [transactionState]);

    useEffect(() => {
        if (open) {
            setDialogState(DialogState.REVIEW);

            // on open, determine applicable product items;
            // we don't have to worry about balances, as Corten
            // will ignore product items with no available amount
            let filters = [];
            if (form.projectType)
                filters.push({ code: carbonProjectAttributes.projectType.key, value: equalsExpr(form.projectType) });
            if (form.project)
                filters.push({ code: carbonProjectAttributes.projectId.key, value: equalsExpr(form.project) });
            if (form.vintage)
                filters.push({ code: carbonProjectAttributes.vintage.key, value: equalsExpr(form.vintage) });
            if (form.projectState)
                filters.push({ code: carbonProjectAttributes.projectState.key, value: equalsExpr(form.projectState) });
            if (form.country)
                filters.push({ code: carbonProjectAttributes.country.key, value: equalsExpr(form.country) });
            if (form.fuelSource)
                filters.push({ code: lgcAttributes.fuelSource.key, value: equalsExpr(form.fuelSource) });
            if (form.creationYear)
                filters.push({ code: lgcAttributes.creationYear.key, value: equalsExpr(form.creationYear) });
            if (form.generationYear)
                filters.push({ code: lgcAttributes.generationYear.key, value: equalsExpr(form.generationYear) });
            if (form.generationState)
                filters.push({ code: lgcAttributes.generationState.key, value: equalsExpr(form.generationState) });
            if (form.greenPowerAccredited)
                filters.push({ code: lgcAttributes.greenPowerAccredited.key, value: equalsExpr(form.greenPowerAccredited) });
            if (form.segment)
                filters.push({ code: miqcAttributes.segment.key, value: equalsExpr(form.segment) });
            if (form.issueYear)
                filters.push({ code: miqcAttributes.issueYear.key, value: equalsExpr(form.issueYear) });
            if (form.miqGrade)
                filters.push({ code: miqcAttributes.miqGrade.key, value: equalsExpr(form.miqGrade) });
            productItemData.current = fetchProductItemsWithId(cortenAuthApi, form.product, filters)
                .then(items => items ? items : []);
        }
    }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDialogClose = () => {
        resetTransactionState();
        setDialogState(null);
        setSubmitting(false);
        setTimeout(() => setTransactionErrorCode(null), 1000); // wait for animation to finish
        setTransactionId(null);
        // if the transaction succeeded - reset trade form
        onClose(DialogState.SUCCESS === dialogState);
    };

    const confirmAndSendRequest = async () => {
        setSubmitting(true);
        const request = await buildUnsignedRequest(form);
        submitAsync(request);
    };

    const onTransactionError = (code: string | null = null) => {
        setTransactionErrorCode(code);
        setDialogState(DialogState.ERROR);
    };

    const getValueDate = (): any => {
        let forwardDate = dayjs();
        let forwardDateOption = '';
        if (form.valueDateOption === 'Custom') {
            forwardDate = form.customValueDate;
        } else {
            forwardDate = forwardDate.add(parseInt(form.valueDateOption), 'month');
            forwardDateOption = ` (T + ${form.valueDateOption} month${form.valueDateOption === '1' ? '' : 's'})`
        }
        return {
            value: forwardDate,
            display: `${dayjs(forwardDate.toDate()).format(DATE_DISPLAY_FORMAT)}${forwardDateOption}`
        }
    };

    const buildUnsignedRequest = async (data: ForwardTradeForm) => {

        const getCountryCode = (): string => {
            // ACCUs will always be AU
            return appConfigState.getProduct(data.product)?.displayCode === ProductType.ACCU ? 'AU' : data.country
        }

        const getProjectName = (): string | undefined => {
            return productItemAttributes[`${carbonProjectAttributes.projectName.key}`]
        }

        let productItemAttributes = data.project !== "" ? (await productItemData.current)![0].productItem.data.attributes : {};
        const request: CreateProductItemRequest = {
            type: "CreateProductItemRequest",
            issuerId: inventoryAccount.id,
            productId: data.product,
            canInflate: false,
            canFractionalize: true,
            isUnassigned: true,
            unitAmount: 1,
            initialAmount: data.quantity,
            toAccountId: data.counterparty,
            nonce: new Date().getTime(),
            attributes: {
                ...data.tradeId && { [`${transactionAttributes.tradeId.key}`]: data.tradeId },
                // TODO MBL-201: keep time component
                [`${transactionAttributes.tradeDate.key}`]: data.tradeDate.startOf('day').toDate().getTime(),
                [`${transactionAttributes.trader.key}`]: data.trader,
                ...data.projectType && {[`${carbonProjectAttributes.projectType.key}`]: data.projectType},
                ...data.project && {[`${carbonProjectAttributes.projectId.key}`]: data.project},
                ...data.project && getProjectName() && {[`${carbonProjectAttributes.projectName.key}`]: getProjectName()},
                ...data.vintage && {[`${carbonProjectAttributes.vintage.key}`]: data.vintage},
                ...data.projectState && {[`${carbonProjectAttributes.projectState.key}`]: data.projectState},
                ...getCountryCode() && {[`${carbonProjectAttributes.country.key}`]: getCountryCode()},
                ...data.fuelSource && {[`${lgcAttributes.fuelSource.key}`]: data.fuelSource},
                ...data.creationYear && {[`${lgcAttributes.creationYear.key}`]: data.creationYear},
                ...data.generationYear && {[`${lgcAttributes.generationYear.key}`]: data.generationYear},
                ...data.generationState && {[`${lgcAttributes.generationState.key}`]: data.generationState},
                ...data.greenPowerAccredited && {[`${lgcAttributes.greenPowerAccredited.key}`]: stringToBoolean(data.greenPowerAccredited)},
                ...data.segment && {[`${miqcAttributes.segment.key}`]: data.segment},
                ...data.issueYear && {[`${miqcAttributes.issueYear.key}`]: data.issueYear},
                ...data.miqGrade && {[`${miqcAttributes.miqGrade.key}`]: data.miqGrade},
                [`${transactionAttributes.price.key}`]: data.price,
                [`${transactionAttributes.currency.key}`]: data.currency.toString(),
                [`${transactionAttributes.valueDate.key}`]: getValueDate().value.startOf('day').toDate().getTime(),
                ...data.salesPerson && { [`${transactionAttributes.salesPerson.key}`]: data.salesPerson },
                ...data.salesCredits && { [`${transactionAttributes.salesCredits.key}`]: data.salesCredits },
                ...data.broker && { [`${transactionAttributes.brokerName.key}`]: data.broker },
                ...data.brokerageFee && { [`${transactionAttributes.brokerage.key}`]: data.brokerageFee },
                [`${transactionAttributes.transactionTypeInventory.key}`]: TransactionType.ForwardSell,
                [`${transactionAttributes.transactionTypeClient.key}`]: TransactionType.ForwardBuy
            }
        }
        return request;
    };

    if (DialogState.SUCCESS === dialogState) {
        return (
            <TransactionOverview
                open={DialogState.SUCCESS === dialogState}
                onClose={handleDialogClose}
                title="Transaction Submitted Successfully"
                uiElements={{
                    transactionId: { value: transactionId! },
                    ...(form.tradeId && { tradeId: { value: form.tradeId } }),
                    tradeDate: { value: form.tradeDate.toISOString() },
                    trader: { value: form.trader },
                    tradeDirection: { value: form.tradeDirection },
                    counterParty: { value: appConfigState.getClientForAddress(form.counterparty) },
                    productId: { value: form.product },
                    ...getProductAttributeUiElements({
                        data: {
                            ...form,
                            projectName: projectName
                        },
                        fieldDefinitions: fieldDefinitions,
                        productType: appConfigState.getProduct(form.product)
                            ?.displayCode as ProductType
                    }),
                    quantity: { value: form.quantity.toString() },
                    valueDate: { value: getValueDate().display },
                    currency: { value: form.currency },
                    price: { value: form.price },
                    ...(form.salesCredits && { salesCredits: { value: form.salesCredits } }),
                    ...(form.salesPerson && { salesPerson: { value: form.salesPerson } }),
                    ...(form.brokerageFee && { brokerage: { value: form.brokerageFee } }),
                    ...(form.broker && { brokerName: { value: form.broker } })
                }}
                relatedProperties={new Map([['counterPartyToolTipText', form.counterparty]])}
                fieldDefinitionMap={fieldDefinitionMap}
                productType={appConfigState.getProduct(form.product)?.displayCode as ProductType}
            />
        );
    }
   

    return (
        <Container>
            <Dialog open={DialogState.REVIEW === dialogState} onClose={handleDialogClose} fullWidth maxWidth='sm'>
                <DialogContent>
                    <Typography variant='h2'>Review Details Below</Typography>
                    {form.tradeId && renderDialogField('Trade ID', form.tradeId)}
                    {renderDialogField('Trade Date', dayjs(form.tradeDate).format(DATE_DISPLAY_FORMAT))}
                    {renderDialogField('Trader', form.trader)}
                    {renderDialogField('Trade Direction', form.tradeDirection)}
                    {renderDialogField('Counterparty', appConfigState.getClientForAddress(form.counterparty))}
                    {renderDialogField('Product', appConfigState.getProduct(form.product)?.displayCode)}
                    {form.projectType && renderDialogField('Project Type', form.projectType)}
                    {form.project && renderDialogField('Project', projectName)}
                    {form.vintage && renderDialogField('Vintage', form.vintage)}
                    {form.projectState && renderDialogField('State', form.projectState)}
                    {form.country && renderDialogField('Country', form.country)}
                    {form.fuelSource && renderDialogField('Fuel Source', form.fuelSource)}
                    {form.creationYear && renderDialogField('Creation Year', form.creationYear)}
                    {form.generationYear && renderDialogField('Generation Year', form.generationYear)}
                    {form.generationState && renderDialogField('Generation State', form.generationState)}
                    {form.greenPowerAccredited && renderDialogField('GreenPower Accredited', form.greenPowerAccredited)}
                    {form.segment && renderDialogField('Segment', form.segment)}
                    {form.issueYear && renderDialogField('Issue Year', form.issueYear)}
                    {form.miqGrade && renderDialogField('MiQ Grade', form.miqGrade)}
                    {renderDialogField('Quantity', form.quantity, {
                        minDecimalPos: productsData.get(form.product)?.minDecimalPos!,
                        maxDecimalPos: productsData.get(form.product)?.maxDecimalPos!,
                    })}
                    {renderDialogField('Value Date', getValueDate().display)}
                    {renderDialogField('Currency', form.currency)}
                    {renderDialogField('Price', form.price, {
                        minDecimalPos: 2,
                        maxDecimalPos: 2,
                    })}
                    {form.salesCredits && renderDialogField('Sales Credits', form.salesCredits, {
                        minDecimalPos: 2,
                        maxDecimalPos: 2,
                    })}
                    {form.salesPerson && renderDialogField('Sales Person', form.salesPerson)}
                    {form.brokerageFee && renderDialogField('Brokerage Fee', form.brokerageFee, {
                        minDecimalPos: 2,
                        maxDecimalPos: 2,
                    })}
                    {form.broker && renderDialogField('Broker Name', form.broker)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color='primary' variant='outlined'>Cancel</Button>
                    <Button onClick={confirmAndSendRequest}
                            color='primary'
                            variant='outlined'>
                        {submitting ? (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '2px',
                            }}>
                                <CircularProgress size={20} />
                            </div>
                        ) : ('Confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={DialogState.ERROR === dialogState} onClose={handleDialogClose}>
                {transactionErrorCode !== 'TIMEOUT' && (
                    <AlertDialogContent
                        alertType={AlertType.Error}
                        alertMessage='An error occurred while submitting the form.'
                        errorCode={transactionErrorCode}
                        handleDialogClose={handleDialogClose}
                    />
                )}

                {transactionErrorCode === 'TIMEOUT' && (
                    <AlertDialogContent
                        alertType={AlertType.Warning}
                        alertMessage='The transaction timed out. Please check back later to confirm whether the transaction was
                    processed.'
                        errorCode={null}
                        handleDialogClose={handleDialogClose}
                    />
                )}
            </Dialog>
        </Container>
    );
};

export { ForwardTradeSubmitDialog };
