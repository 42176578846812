var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Option, Options } from '../state/ProductItemFilters';
import { ProductType, carbonProjectAttributes, lgcAttributes, inventoryScoreAttribute, miqcAttributes } from '../state/AppConfig';
import { fetchAccountBalances, fetchOrderedProductItems } from './Fetch';
import { AmountFormatWrapper } from './AmountFormatWrapper';
import { equalsExpr } from "@trovio-tech/trovio-core-api-js";
var TradeOptimiser;
(function (TradeOptimiser) {
    TradeOptimiser["DEFAULT"] = "Default";
    TradeOptimiser["CHEAPEST_FIRST"] = "Cheapest First";
    TradeOptimiser["INVENTORY_SCORE_BASED"] = "Inventory Score Based";
})(TradeOptimiser || (TradeOptimiser = {}));
;
var TradeOptimiserAmountType;
(function (TradeOptimiserAmountType) {
    TradeOptimiserAmountType["Price"] = "Price";
    TradeOptimiserAmountType["Score"] = "Score";
})(TradeOptimiserAmountType || (TradeOptimiserAmountType = {}));
var getTradeOptimiserOptions = function (selectedProduct) {
    var nonDefaultAlgorithmsAllowed = selectedProduct === undefined || [ProductType.ACCU, ProductType.VCU].includes(selectedProduct);
    var values = [
        new Option(TradeOptimiser.DEFAULT, TradeOptimiser.DEFAULT),
        new Option(TradeOptimiser.CHEAPEST_FIRST, TradeOptimiser.CHEAPEST_FIRST, undefined, !nonDefaultAlgorithmsAllowed),
        new Option(TradeOptimiser.INVENTORY_SCORE_BASED, TradeOptimiser.INVENTORY_SCORE_BASED, undefined, !nonDefaultAlgorithmsAllowed)
    ];
    return new Options(values);
};
;
var getOrderedProjectsAndAmounts = function (form, accountId, setProjectAmounts, productItemIds, appConfigState, cortenAuthApi, api, handleError) {
    if (handleError === void 0) { handleError = undefined; }
    switch (form.tradeOptimiser) {
        case TradeOptimiser.DEFAULT:
        case TradeOptimiser.INVENTORY_SCORE_BASED:
            var filters = [];
            if (form.projectType) {
                filters.push({
                    code: carbonProjectAttributes.projectType.key,
                    value: equalsExpr(form.projectType),
                });
            }
            if (form.project) {
                filters.push({
                    code: carbonProjectAttributes.projectId.key,
                    value: equalsExpr(form.project)
                });
            }
            if (form.vintage) {
                filters.push({
                    code: carbonProjectAttributes.vintage.key,
                    value: equalsExpr(form.vintage)
                });
            }
            if (form.projectState) {
                filters.push({
                    code: carbonProjectAttributes.projectState.key,
                    value: equalsExpr(form.projectState)
                });
            }
            if (form.country) {
                filters.push({
                    code: carbonProjectAttributes.country.key,
                    value: equalsExpr(form.country)
                });
            }
            if (form.fuelSource) {
                filters.push({
                    code: lgcAttributes.fuelSource.key,
                    value: equalsExpr(form.fuelSource)
                });
            }
            if (form.creationYear) {
                filters.push({
                    code: lgcAttributes.creationYear.key,
                    value: equalsExpr(form.creationYear)
                });
            }
            if (form.generationYear) {
                filters.push({
                    code: lgcAttributes.generationYear.key,
                    value: equalsExpr(form.generationYear)
                });
            }
            if (form.generationState) {
                filters.push({
                    code: lgcAttributes.generationState.key,
                    value: equalsExpr(form.generationState)
                });
            }
            if (form.greenPowerAccredited) {
                filters.push({
                    code: lgcAttributes.greenPowerAccredited.key,
                    value: equalsExpr(form.greenPowerAccredited)
                });
            }
            if (form.segment) {
                filters.push({
                    code: miqcAttributes.segment.key,
                    value: equalsExpr(form.segment)
                });
            }
            if (form.issueYear) {
                filters.push({
                    code: miqcAttributes.issueYear.key,
                    value: equalsExpr(form.issueYear)
                });
            }
            if (form.miqGrade) {
                filters.push({
                    code: miqcAttributes.miqGrade.key,
                    value: equalsExpr(form.miqGrade)
                });
            }
            var fetchBalancesCriteria = {
                productId: [form.product],
                accountId: accountId,
                sumProductItems: false,
                includeProductItemData: true,
                attributes: filters
            };
            productItemIds.current = fetchAccountBalances({
                api: cortenAuthApi,
                criteria: fetchBalancesCriteria,
                appConfigState: appConfigState
            }).then(function (items) {
                if (form.tradeOptimiser === TradeOptimiser.DEFAULT) {
                    setProjectAmounts(items ? items.map(function (item) {
                        return {
                            projectName: item.productItem.data.attributes[carbonProjectAttributes.projectName.key],
                            balance: +item['assignedAmount']
                        };
                    }) : []);
                    return items ? items.map(function (item) { return item['productItemId']; }) : [];
                }
                else if (form.tradeOptimiser === TradeOptimiser.INVENTORY_SCORE_BASED) {
                    var remainingQuantity = form.quantity;
                    var selectedProjectAmounts = [];
                    // take just enough product items to fulfil the requested quantity
                    for (var _i = 0, _a = items ? items.sort(function (a, b) {
                        var _a, _b, _c, _d;
                        // Sort items by score ascending
                        var attributesA = a.productItem.data.attributes;
                        var attributesB = b.productItem.data.attributes;
                        var scoreA = parseFloat(((_a = attributesA[inventoryScoreAttribute.key]) !== null && _a !== void 0 ? _a : "0").toString());
                        var scoreB = parseFloat(((_b = attributesB[inventoryScoreAttribute.key]) !== null && _b !== void 0 ? _b : "0").toString());
                        var diff = scoreA - scoreB;
                        if (diff !== 0) {
                            return diff;
                        }
                        // Include secondary sort on vintage ascending
                        scoreA = parseFloat(((_c = attributesA[carbonProjectAttributes.vintage.key]) !== null && _c !== void 0 ? _c : "0").toString());
                        scoreB = parseFloat(((_d = attributesB[carbonProjectAttributes.vintage.key]) !== null && _d !== void 0 ? _d : "0").toString());
                        diff = scoreA - scoreB;
                        if (diff !== 0) {
                            return diff;
                        }
                        // Tertiary sort on product item ID just to ensure a deterministic result
                        return a.productItemId.localeCompare(b.productItemId);
                    }) : []; _i < _a.length; _i++) {
                        var item = _a[_i];
                        var itemBalance = item.assignedAmount;
                        if (itemBalance === 0) {
                            continue;
                        }
                        var attributes = item.productItem.data.attributes;
                        var selectedItem = {
                            projectName: attributes[carbonProjectAttributes.projectName.key],
                            projectId: attributes[carbonProjectAttributes.projectId.key],
                            vintage: attributes[carbonProjectAttributes.vintage.key],
                            balance: Math.min(remainingQuantity, itemBalance),
                            productItemIds: [item.productItemId],
                            score: attributes[inventoryScoreAttribute.key],
                            productId: form.product
                        };
                        selectedProjectAmounts.push(selectedItem);
                        remainingQuantity -= selectedItem.balance;
                        if (remainingQuantity <= 0)
                            break;
                    }
                    setProjectAmounts(selectedProjectAmounts);
                    return selectedProjectAmounts.flatMap(function (item) { return item.productItemIds; });
                }
                else {
                    throw Error('Trade sub-optimiser not implemented');
                }
            }).catch(function (error) {
                handleError();
                return [];
            });
            break;
        case TradeOptimiser.CHEAPEST_FIRST:
            productItemIds.current = fetchOrderedProductItems({
                request: __assign(__assign(__assign(__assign(__assign({ productId: form.product, accountId: accountId }, (form.projectType && { projectTypes: [form.projectType] })), (form.project && { projects: [form.project] })), (form.vintage && { vintages: [form.vintage] })), (form.projectState && { states: [form.projectState] })), (form.country && { countries: [form.country] })),
                api: api
            }).then(function (result) {
                if (result === undefined) {
                    handleError();
                    return [];
                }
                var remainingQuantity = form.quantity;
                var selectedProjectAmounts = [];
                // take just enough project-vintage pairs to fulfil the requested quantity
                for (var _i = 0, _a = result.projectVintageSummaries; _i < _a.length; _i++) {
                    var summary = _a[_i];
                    var item = {
                        projectName: summary.projectName,
                        projectId: summary.projectId,
                        vintage: summary.vintage,
                        balance: Math.min(remainingQuantity, summary.availableBalance),
                        productItemIds: summary.productItemIds,
                        price: summary.price,
                        productId: form.product
                    };
                    selectedProjectAmounts.push(item);
                    remainingQuantity -= item.balance;
                    if (remainingQuantity <= 0)
                        break;
                }
                setProjectAmounts(selectedProjectAmounts);
                return selectedProjectAmounts.flatMap(function (item) { return item.productItemIds; });
            });
            break;
        default:
            throw Error('Trade optimiser not implemented');
    }
};
var renderOrderedProjectVintageAmounts = function (projectAmounts, productData, amountType) {
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ variant: "h2", mt: 4 }, { children: "Project Quantities" })), projectAmounts == null ? (_jsx(LinearProgress, {})) : (_jsx(TableContainer, __assign({ component: Paper }, { children: _jsxs(Table, __assign({ size: "small" }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Project" }), _jsx(TableCell, { children: "Vintage" }), _jsx(TableCell, { children: "Quantity" }), amountType === TradeOptimiserAmountType.Price && (_jsx(TableCell, { children: "Price" })), amountType === TradeOptimiserAmountType.Score && (_jsx(TableCell, { children: "Score" }))] }) }), _jsx(TableBody, { children: projectAmounts.map(function (item) { return renderProjectVintageAmount(item, productData, amountType); }) })] })) })))] }));
};
var renderProjectVintageAmount = function (item, productData, amountType) {
    return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: "".concat(item.projectId, " - ").concat(item.projectName) }), _jsx(TableCell, { children: item.vintage }), _jsx(TableCell, __assign({ align: "right" }, { children: _jsx(AmountFormatWrapper, { amount: item.balance, minDecimalPos: productData.minDecimalPos, maxDecimalPos: productData.minDecimalPos }) })), amountType === TradeOptimiserAmountType.Price && (_jsxs(TableCell, __assign({ align: "right" }, { children: [item.price && (_jsx(AmountFormatWrapper, { amount: item.price, minDecimalPos: 2, maxDecimalPos: 2 })), !item.price && (_jsx(_Fragment, { children: "-" }))] }))), amountType === TradeOptimiserAmountType.Score && (_jsxs(TableCell, __assign({ align: "right" }, { children: [item.score && (_jsx(AmountFormatWrapper, { amount: item.score, minDecimalPos: 2, maxDecimalPos: 2 })), !item.score && (_jsx(_Fragment, { children: "-" }))] })))] }, item.productItemName));
};
export { TradeOptimiser, TradeOptimiserAmountType, getTradeOptimiserOptions, getOrderedProjectsAndAmounts, renderOrderedProjectVintageAmounts };
