(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@trovio-tech/trovio-core-api-jsx"), require("react-dom"), require("react-router-dom"), require("@commodity-desk/commodity-desk-api-js"), require("@trovio-ui-libs/ui"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@trovio-tech/trovio-core-api-jsx", "react-dom", "react-router-dom", "@commodity-desk/commodity-desk-api-js", "@trovio-ui-libs/ui"], factory);
	else if(typeof exports === 'object')
		exports["$"] = factory(require("react"), require("@trovio-tech/trovio-core-api-jsx"), require("react-dom"), require("react-router-dom"), require("@commodity-desk/commodity-desk-api-js"), require("@trovio-ui-libs/ui"));
	else
		root["$"] = factory(root["React"], root["@trovio-tech/trovio-core-api-jsx"], root["ReactDOM"], root["react-router-dom"], root["@commodity-desk/commodity-desk-api-js"], root["@trovio-ui-libs/ui"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__4012__, __WEBPACK_EXTERNAL_MODULE__4197__, __WEBPACK_EXTERNAL_MODULE__6033__, __WEBPACK_EXTERNAL_MODULE__4442__, __WEBPACK_EXTERNAL_MODULE__8466__, __WEBPACK_EXTERNAL_MODULE__7426__) => {
return 