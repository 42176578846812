import {
    appThemes, 
    AuthExtension, 
    CustomTheme, 
    StorageItem,
    useAppConfigState
} from '@commodity-desk/common';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useAuth } from '@trovio-tech/trovio-core-api-jsx';
import { useState } from 'react';
import './App.css';
import AppContainer from './component/AppContainer';
import {
    ADMIN_GROUP_ID,
    STORAGE_KEY_PREFIX
} from './state/Variables';

function App() {
    var themeName: keyof typeof appThemes = 'trovio-dark';
    const [theme, setTheme] = useState<CustomTheme>(appThemes[themeName]);
    const [themeAnchorEl, setThemeAnchorEl] = useState();
    StorageItem.initItems(STORAGE_KEY_PREFIX);

    return (
        <ThemeProvider theme={theme.theme}>
            <CssBaseline />
            <AuthConsumer
                theme={theme}
                setTheme={setTheme}
                themeAnchorEl={themeAnchorEl}
                setThemeAnchorEl={setThemeAnchorEl}
            />
        </ThemeProvider>
    );
}

// In order to use useAuth(), we need to wrap it in a component that is also wrapped in AuthProvider
const AuthConsumer = ({
    theme,
    setTheme,
    themeAnchorEl,
    setThemeAnchorEl
}: {
    theme: CustomTheme;
    setTheme: any;
    themeAnchorEl: any;
    setThemeAnchorEl: any;
}) => {
    const {userManager, user, signOut} = useAuth();

    return (
        <>
            <AuthExtension
                cognitoGroupId={ADMIN_GROUP_ID}
                userManager={userManager}
                user={user}
                signOut={signOut}
            >
                <AppContainer
                    theme={theme}
                    setTheme={setTheme}
                    themeAnchorEl={themeAnchorEl}
                    setThemeAnchorEl={setThemeAnchorEl}
                />
            </AuthExtension>
        </>
    );
};

export default App;
